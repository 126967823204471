import React, { useRef } from 'react'
import { PropTypes } from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import styles from './dataPicker.module.scss'
import ErrorMessage from '../errorMessage/index'
import { calendarPreviewFormat, calendarPreviewTimeFormat } from 'constants/date'

const DatePickerComp = ({
  onChange,
  stylesClass = 'defaultPicker',
  globalClass = '',
  startDate,
  name,
  label,
  showTime = false,
  isClearable = true,
  format = calendarPreviewFormat,
  timeFormat = calendarPreviewTimeFormat,
  placeholder = 'YYYY-MM-DD',
  errorTxt,
  haveIcon = true,
  showTimeSelectOnly = false
}) => {
  const datepickerRef = useRef(null)
  function handleClickDatepickerIcon() {
    const datepickerElement = datepickerRef.current
    datepickerElement.setFocus(true)
  }
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-picker`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.picker}>
        <DatePicker
          selected={startDate}
          onChange={date => onChange(date, name)}
          showTimeSelect={showTime}
          showTimeSelectOnly={showTimeSelectOnly}
          timeFormat={timeFormat}
          timeIntervals={15}
          timeCaption="time"
          dateFormat={format}
          isClearable={isClearable}
          placeholderText={placeholder}
          ref={datepickerRef}
        />
        {haveIcon && (
          <span className={styles.icon} onClick={() => handleClickDatepickerIcon()}>
            <FontAwesomeIcon icon="fa-calendar-days" />
          </span>
        )}
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

DatePickerComp.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  startDate: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  showTime: PropTypes.bool,
  isClearable: PropTypes.bool,
  format: PropTypes.string,
  placeholder: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  haveIcon: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool
}
export default DatePickerComp
