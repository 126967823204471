import { useLoginMutation } from 'api/authApiSlice'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { BoxContainer } from 'components/containers/containers'
import Input from 'components/input'
import { initialLogin } from 'constants/initialValues'
import { queryAddActivity } from 'query/queryActivity'
import { useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/button/index'
import InputPassword from '../../components/inputPassword/index'
import styles from './auth.module.scss'

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  // const dispatch = useDispatch()
  const from = location.state?.from?.pathname || '/'
  const [error, setError] = useState(false)
  const [data, setData] = useState({ ...initialLogin })

  const [login] = useLoginMutation()
  const [loading, setLoading] = useState(false)
  const [create] = useUpdateCreateOneMutation()
  const handleChange = e => {
    setError(false)
    const { name, value } = e.target
    setData(userData => ({ ...userData, [name]: value }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (validateForm()) {
      setLoading(true)
      const res = await login(data)
      if (!res.error) {
        await create(queryAddActivity(res.data.id, 'logged', 'klijent', res.data))
        navigate(from, { replace: true })
      }

      setLoading(false)
    } else {
      setError(true)
    }
  }

  const validateForm = () => [data.email, data.password].every(Boolean)
  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        <BoxContainer globalClass="p-3">
          <div>
            <h2>Prijavi se</h2>
            <Input
              className="form-control"
              name="email"
              value={data.email}
              label="Email:"
              onChange={handleChange}
              placeholder="Email..."
              errorTxt={error && !data.email && 'Email je obavezno polje'}
              globalClass="mb-2"
            />
            <InputPassword
              className="form-control"
              name="password"
              value={data.password}
              label="Šifra:"
              onChange={handleChange}
              placeholder="Šifra..."
              errorTxt={error && !data.password && 'Password je obavezno polje'}
              globalClass="mb-2"
            />
          </div>
          <div className={styles.btn}>
            <Button onClick={e => handleSubmit(e)} label="Prijava" loading={loading} disabled={loading} globalClass="mt-2 w-100" />
          </div>
          <div className="ta-c my-3 w-100">
            Ukoliko ne postoji vaš nalog potrebno je odraditi <NavLink to="/register">Registraciju</NavLink>
          </div>
          <div className="ta-c my-3 w-100">
            <NavLink to="/forgot-password">Zaboravljena sifra!</NavLink>
          </div>
        </BoxContainer>
      </div>
    </form>
  )
}
export default Login
