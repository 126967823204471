/** Queries for 'Country' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
import { initialCountry } from 'constants/initialValues'
import { label } from 'constants/labels'

export const queryGetPagedCountry = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'drzava',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.country
    }
  }
}

export const queryGetAllCountry = (options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'drzava',
      data: { options }
    },
    providesTags: {
      type: tag.country
    }
  }
}
/**
 * @param {initialCountry} data
 */
export const queryAddCountry = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'drzava',
      data: { values: data }
    },
    invalidatesTags: [tag.country],
    successMsg: label.successMsgCreate + ' ' + label.msgCountry
  }
}

/**
* @param {initialCountry} data
@param {string} id
*/
export const queryUpdateCountry = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'drzava',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.country],
    successMsg: label.successMsgEdit + ' ' + label.msgCountry
  }
}
/**
@param {string} id
*/
export const queryDeleteCountry = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'drzava',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.country],
    successMsg: label.successMsgDelete + ' ' + label.msgCountry
  }
}
