import App from 'App'
import ForgotPassword from 'pages/auth/forgotPassword'
import Login from 'pages/auth/login'
import Register from 'pages/auth/register'
import ResetPassword from 'pages/auth/resetPassword'
import BasicComponents from 'pages/basicComponents'
import DayliAnnouncment from 'pages/dailyAnnouncment'
import ExportImport from 'pages/exportImport'
import Landing from 'pages/landing'
import MyAnnouncments from 'pages/myAnnouncments'
import NotFound from 'pages/notFound'
import PriceFlow from 'pages/priceFlow'
import StockMarket from 'pages/stockMarket'
import { useRoutes } from 'react-router-dom'
import Auth from './auth'
import NonAuth from './nonAuth'
import Public from './public'

const AppRoutes = () => {
  const element = useRoutes([
    {
      path: '/',
      element: <App />,
      children: [
        // routes that can be accessed by both auth and non-auth users
        {
          element: <Public />,
          children: [
            { path: '', element: <Landing /> },
            { path: 'components', element: <BasicComponents /> }
          ]
        },
        {
          // routes that can be accessed just by non-auth users
          // wrapped with NonAuth
          element: <NonAuth />,
          children: [
            { path: 'login', element: <Login /> },
            { path: 'register', element: <Register /> },
            { path: 'forgot-password', element: <ForgotPassword /> },
            { path: 'reset/:forgotToken/:email', element: <ResetPassword /> }
          ]
        },
        {
          // routes that can access just auth users
          // wrapped with Auth
          element: <Auth />,
          children: [
            { path: 'dnevni-oglasi', element: <DayliAnnouncment /> },
            { path: 'kretanje-cena', element: <PriceFlow /> },
            { path: 'uvoz-izvoz', element: <ExportImport /> },
            { path: 'berze', element: <StockMarket /> },
            { path: 'moji-oglasi', element: <MyAnnouncments /> }
          ]
        },
        { path: '*', element: <NotFound /> }
      ]
    }
  ])

  return element

  // or
  // return (
  //   <Routes>
  //     <Route path="/" element={<App />}>
  //       {/* routes that can be accessed by both auth and non-auth users */}
  //       <Route path="" element={<Landing />} />

  //       <Route path="test-route-1" element={<TestRoute1 />} >
  //         {/* // example when you want to render on the same page parent and child bellow */}
  //         <Route path="child-1" element={<TestRoute4 />} />
  //       </Route>

  //       {/* routes that can be accessed just by non-auth users */}
  //       <Route element={<NonAuth />} >
  //         <Route path="login" element={<Login />} />
  //         <Route path="test-route-2" element={<TestRoute2 />} />
  //       </Route>

  //       {/* can access just auth users */}
  //       <Route element={<Auth />} >
  //         <Route path="app" element={<Dashboard />} />
  //         <Route path="test-route-3" element={<TestRoute3 />} />
  //         {/* example when you want to base path be the same for children (users/) but every child inside will be rendered on new page not bellow users */}
  //         <Route path="users" element={<Outlet />} >
  //           <Route path="" element={<Users />} />
  //           <Route path=":id" element={<User />} />
  //           <Route path="add" element={<User />} />
  //         </Route>
  //       </Route>
  //       <Route path="*" element={<NotFound />} />
  //     </Route>
  //   </Routes>
  // )
}

export { AppRoutes }
