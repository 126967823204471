export const FIELD_TYPES = {
  text: 'TEXT',
  integer: 'INTEGER',
  boolean: 'BOOLEAN',
  date: 'DATETIME',
  decimal: 'DECIMAL',
  image: 'IMAGE',
  actions: 'ACTIONS',
  history: 'HISTORY',
  customGoodsImportAndExport: 'CUSTOM_GOOODS_IMPORT_AND_EXPORT',
  maped: 'MAPED',
  status: 'STATUS',
  range: 'RANGE',
  join: 'JOIN',
  joinTwo: 'JOINTWO',
  joinThree: 'JOINTHREE',
  joinFour: 'JOINFOUR',
  link: 'LINK',
  arrOfObject: 'ARR_OBJ'
}

export const CLIENT_CONNECTION_TB = {
  activity: 'clientActivity',
  contact: 'clientContacts',
  timeOfContacts: 'timeOfContacts',
  clientCelebration: 'clientCelebration',
  productsBuy: 'productsBuy',
  productsSell: 'productsSell'
}

export const DATA_TYPES = {
  categorySerbia: 'kategorijaSrbija',
  categoryBih: 'kategorijaBih',
  categoryFutures: 'kategorijaFutures',
  categoryPort: 'kategorijaLuka',
  productsSerbia: 'proizvodSrbija',
  productsBih: 'proizvodBih',
  productsFutures: 'proizvodFutures',
  productsPort: 'proizvodLuka',
  productsMilano: 'proizvodMilano'
}

export const PAGE_SIZE = 30
