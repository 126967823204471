export const ANNOUNCEMENT_STATUS = [
  { label: 'Razmatranje', id: 1 },
  { label: 'Nedovršeno', id: 5 },
  { label: 'Odobreno', id: 2 },
  { label: 'Neaktivan', id: 4 },
  { label: 'Odbijeno', id: 3 }
]

export const PAYMENT_TYPE = [
  { id: 1, label: 'Žiralno plaćanje' },
  { id: 2, label: 'Avansno plaćanje' },
  { id: 3, label: 'Dogovor' },
  { id: 4, label: 'Kompezacija' },
  { id: 5, label: 'Odloženo plaćanje' },
  { id: 6, label: 'Akontaciono plaćanje' }
]
