import { dateFormat, timeFormat } from './date'
import { label } from './labels'
import { FIELD_TYPES } from './other'

export const headingTest = [
  { label: 'Image', dbName: 'prodImg', type: FIELD_TYPES.image, additionalField: 'listImgs' },
  { label: 'First name', dbName: 'firstName', type: FIELD_TYPES.text },
  { label: 'Last name', dbName: 'lastName', type: FIELD_TYPES.text },
  { label: 'Age', dbName: 'age', type: FIELD_TYPES.integer },
  { label: 'Is true', dbName: 'isTrue', type: FIELD_TYPES.boolean },
  { label: 'Time format', dbName: 'time', type: FIELD_TYPES.date, format: timeFormat },
  { label: 'Date format', dbName: 'date', type: FIELD_TYPES.date, format: dateFormat }
]
export const headingTest2 = [
  { label: 'First name', dbName: 'firstName', type: FIELD_TYPES.text },
  { label: 'Last name', dbName: 'lastName', type: FIELD_TYPES.text },
  { label: 'Date format', dbName: 'date', type: FIELD_TYPES.date, format: dateFormat },
  { label: '', dbName: 'actions', type: FIELD_TYPES.actions, actions: ['edit', 'duplicate', 'delete'] }
]

export const headingAnnouncement = [
  // { label: 'ID', dbName: 'id', type: FIELD_TYPES.text },
  { label: label.products, dbName: 'proizvod', nestedObj: 'naziv', type: FIELD_TYPES.text },
  { label: label.productsDescInput, dbName: 'opisProizvodaNaziv', type: FIELD_TYPES.text },
  // { label: label.priceInput, dbName: 'cena', type: FIELD_TYPES.decimal },
  // { label: label.amountInput, dbName: 'kolicina', type: FIELD_TYPES.text },
  { label: label.amountInput, dbName1: 'kolicina', dbName3: 'unetaJedinicaMere', type: FIELD_TYPES.join, numFormat: 'money' },
  {
    label: label.priceInput,
    dbName1: 'cena',
    dbName2: 'valuta',
    dbName3: 'unetaCenaJedinicaMere',
    type: FIELD_TYPES.join,
    numFormat: 'money'
  },
  { label: label.cities, dbName: 'gradovi', type: FIELD_TYPES.text },
  { label: label.date, dbName: 'createdAt', type: FIELD_TYPES.date, style: { width: '100px' } }
]
