/** Queries for 'Gazdinstva' */

import { tag } from 'api/rtkTags'

/**
@param {string} id
*/
export const queryGetAllActivityByClientId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'delatnostKlijent',
      data: {
        options: {
          where: { klijentId: id }
        }
      }
    },
    providesTags: {
      type: tag.clientActivity
    }
  }
}

/**
@param {string} id
*/
export const queryGetAllContactsByClientId = options => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'kontaktOsoba',
      data: {
        options: {
          ...options,
          order: [['ime', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.clientContacts
    }
  }
}

/**
@param {string} id
*/
export const queryGetAllContactsTimeByClientId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'vremeKontaktaKlijenta',
      data: {
        options: {
          where: { klijentId: id }
        }
      }
    },
    providesTags: {
      type: tag.clientTimeContacts
    }
  }
}

export const queryAddContactsTimeForClientId = data => {
  return {
    query: {
      name: 'data',
      mode: 'newMulti',
      type: 'vremeKontaktaKlijenta',
      data: { values: data }
    },
    providesTags: {
      type: tag.clientTimeContacts
    }
  }
}
/**
@param {string} id
@param {string} tip // kupovina /prodaja
*/
export const queryGetAllProductsByClientId = (id, tip) => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'proizvodKlijenta',
      data: {
        options: {
          where: { klijentId: id, tip }
        }
      }
    },
    providesTags: {
      type: tag.clientsProducts
    }
  }
}

/**
@param {string} id
*/
export const queryGetAllCelebrations = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'klijentProslave',
      data: {
        options: {
          where: { klijentId: id }
        }
      }
    },
    providesTags: {
      type: tag.clientsCelebrations
    }
  }
}

/**
@param {string} id
*/
export const queryGetAllRemarkByClientId = id => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'klijentNapomene',
      data: {
        options: {
          where: { klijentId: id }
        }
      }
    },
    providesTags: {
      type: tag.clientsRemark
    }
  }
}

/**
@param {string} id
*/
export const queryDeleteActivityById = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'delatnostKlijent',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.clientActivity, tag.client]
  }
}

/**
@param {string} id
*/
export const queryDeleteContactsById = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'kontaktOsoba',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.clientContacts, tag.client]
  }
}

/**
@param {string} id
*/
export const queryDeleteCelebrationsById = id => {
  return {
    query: {
      name: 'data',
      mode: 'delete',
      type: 'klijentProslave',
      data: {
        options: {
          where: { id }
        }
      }
    },
    invalidatesTags: [tag.clientsCelebrations, tag.client]
  }
}

/**
@param {string} id
@param {Object} data
@param {string} data.poruka
*/
export const queryUpdateRemark = (data, id) => {
  return {
    query: {
      name: 'data',
      mode: 'update',
      type: 'klijentNapomene',
      data: {
        values: data,
        options: {
          where: {
            id
          }
        }
      }
    },
    invalidatesTags: [tag.clientsRemark, tag.client]
  }
}
/**
@param {Object} data
@param {string} data.poruka
*/
export const queryCreateRemark = data => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'klijentNapomene',
      data: {
        values: data
      }
    },
    invalidatesTags: [tag.clientsRemark, tag.client]
  }
}
