import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllEntityTypes } from 'query/queryEntityType'
import { queryGetAllProductDesc } from 'query/queryProductDescription'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllRegion } from 'query/queryRegion'
import { label } from './labels'

export const announcementFilter = [
  {
    type: 'range',
    subType: 'datapicker',
    dbField: 'createdAt',
    labelTo: label.dateTo,
    labelFrom: label.dateFrom
  },
  {
    type: 'multiselect',
    dbField: 'proizvodId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProducts(),
    label: label.products
  },
  {
    type: 'multiselect',
    dbField: 'opisProizvodaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllProductDesc(),
    label: label.productsDescInput
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'cena',
    labelTo: 'Cena do',
    labelFrom: 'Cena od'
  },
  {
    type: 'select',
    dbField: 'tipLicaId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllEntityTypes(),
    label: label.typeEntityInput
  },
  {
    type: 'range',
    subType: 'inputRange',
    inputType: 'number',
    dbField: 'kolicina',
    labelTo: 'Kolicina do',
    labelFrom: 'Kolicina od'
  },

  {
    type: 'multiselect',
    dbField: 'gradId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllCity(),
    label: label.cityInput
  },
  {
    type: 'multiselect',
    dbField: 'regionId',
    keyLabel: 'naziv',
    keyValue: 'id',
    rtkQuery: queryGetAllRegion(),
    label: label.regionInput
  }
  // {
  //   type: 'select',
  //   dbField: 'tip',
  //   keyLabel: 'label',
  //   keyValue: 'id',
  //   label: label.bidDemand,
  //   options: ANNOUNCEMENT_TYPE
  // },
]
