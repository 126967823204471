import { createSlice } from '@reduxjs/toolkit'
import { localStorageService } from 'services/localStorage.service'
import { v4 as uuidv4 } from 'uuid'
const initialToken =
  localStorage.getItem('csct') && localStorage.getItem('csct') !== 'undefined' ? JSON.parse(localStorage.getItem('csct')) : null
const initialId =
  localStorage.getItem('csci') && localStorage.getItem('csci') !== 'undefined' ? JSON.parse(localStorage.getItem('csci')) : null

const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, token: initialToken, id: initialId },
  reducers: {
    setCredentials: (state, action) => {
      const { token, id, refreshToken } = action.payload
      state.user = action.payload
      state.token = token
      state.refreshToken = refreshToken
      state.id = id
      localStorageService.set('csct', token)
      localStorageService.set('csci', id)
      localStorageService.set('cscrt', refreshToken)
      const deviceId =
      localStorage.getItem('did') && localStorage.getItem('did') !== 'undefined' ? JSON.parse(localStorage.getItem('did')) : null
    
      console.log('deviceId',deviceId)
      if(!deviceId) localStorageService.set('did', uuidv4())
    },
    logOut: state => {
      localStorageService.remove('csct')
      localStorageService.remove('csci')
      localStorageService.remove('cscrt')
      localStorageService.remove('page')
      state.user = null
      state.token = null
      state.id = null
      state.refreshToken = null
    },
    setUser: (state, action) => {
      state.user = action.payload
    }
  }
})

export const { setCredentials, logOut, setUser } = authSlice.actions

export default authSlice.reducer

export const selectCurrentId = state => state.auth.id
export const selectCurrentUser = state => state.auth.user
