/* eslint-disable no-unused-vars */
/** Page  'Moji oglasi' */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery, useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { useUploadMediaMutation } from 'api/fileApiSlice'
import Button from 'components/button'
import { OneColumn } from 'components/containers/containers'
import FormAnnouncementModal from 'components/forms/formAnnoucments/formModal'
import ModalYesNo from 'components/modal/modalYesNo'
import Search from 'components/search'
import Table from 'components/table'
import Title from 'components/title'
import { label } from 'constants/labels'
import { PAGE_SIZE } from 'constants/other'
import { announcementFilter } from 'constants/searchFields'
import { headingAnnouncement } from 'constants/tbHeading'
import {
  queryAddAnnouncement,
  queryDeleteAnnouncement,
  queryGetPagedAnnouncementSearch,
  queryUpdateAnnouncement
} from 'query/queryAnnouncment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setIsDisabled, setIsLoadingData, setUploadImages } from 'redux/reducers/staticSlice'

const MyAnnouncments = ({ code }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = useSelector(state => state.auth.id)
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState({})
  const [pageSize, setPageSize] = useState(PAGE_SIZE)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchOptions, setSearchOptions] = useState({})
  const [cases, setCases] = useState('')
  const { data: announcement = [] } = useGetAllQuery(
    queryGetPagedAnnouncementSearch(pageNumber, pageSize, { ...searchOptions, where: { klijentId: id } })
  )
  const [loading, setLoading] = useState(false)
  const [uploadMedia] = useUploadMediaMutation()
  const [createUpdateData] = useUpdateCreateOneMutation()
  const [deleteOne] = useDeleteOneMutation()

  useEffect(() => {
    if (id) {
      dispatch(setIsDisabled(true))
      const found = (announcement.rows && announcement.rows.find(e => e.id === +id)) || {}
      setData(found)
    } else {
      setData({})
    }
  }, [id, announcement?.rows])

  useEffect(() => {
    return () => dispatch(setIsDisabled(false))
  }, [])

  const onSubmit = async (data, fileIds) => {
    let uploadRes = []
    dispatch(setIsLoadingData(true))
    setLoading(true)
    if (data.id) {
      if (fileIds.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await createUpdateData(queryUpdateAnnouncement({ ...data, uploadRes }))
      setLoading(false)
      if (!res.error) dispatch(closeModal(1))
    } else {
      if (fileIds.length) {
        uploadRes = await uploadMedia(fileIds)
      }
      const res = await createUpdateData(queryAddAnnouncement({ ...data, uploadRes }))
      setLoading(false)
      if (!res.error) dispatch(closeModal(1))
    }
    dispatch(setIsLoadingData(false))
    dispatch(setUploadImages([]))
    setCases('')
  }

  const onRowClick = data => {
    // dispatch(setIsDisabled(true))
    if (data) {
      setData(data)
      setCases('edit')
    }
  }

  const onActionClick = (data, type) => {
    if (type === 'delete') openDeleteModal(data)
  }

  const onPaginationChange = (currentPage, pageSize) => {
    setPageSize(pageSize)
    setPageNumber(currentPage)
    navigate(`/moji-oglasi`)
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteAnnouncement(data.id))
      if (!res.error) dispatch(closeModal(1))
    } else {
      dispatch(closeModal(1))
    }
  }
  const openDeleteModal = data => {
    dispatch(
      openModal({
        arrItem: {
          content: <ModalYesNo handleSubmit={handleDelete} data={data} question={label.msgDeleteAnnouncement} />,
          order: 1,
          size: 'sm'
        },
        active: 1
      })
    )
    setCases('')
  }
  // const showModal = () => {
  //   navigate(`/moji-oglasi`)
  //   dispatch(
  //     openModal({
  //       arrItem: {
  //         content: (
  //           <FormWrapper>
  //             <h2>{label.addAnnouncement}</h2>
  //             <FormAnnouncementModal onSubmit={onSubmit} id={null} />
  //           </FormWrapper>
  //         ),
  //         order: 1,
  //         size: 'md'
  //       },
  //       active: 1
  //     })
  //   )
  // }

  const onSubmitSearch = async data => {
    setPageNumber(1)
    setSearchOptions(data)
  }

  const goBack = () => {
    setCases('')
  }

  const renderFields = () => {
    switch (cases) {
      case 'add':
        return <FormAnnouncementModal onSubmit={onSubmit} goBack={goBack} />
      case 'edit':
        return <FormAnnouncementModal existingData={data} onSubmit={onSubmit} goBack={goBack} openOkModal={openDeleteModal} />
      case '':
        return (
          <div>
            <Title title="Moji Oglasi" total={announcement?.count}>
              <Button
                onClick={() => setCases('add')}
                label={label.add}
                iconLeft={<FontAwesomeIcon icon="fa-plus" />}
                accessAction="ADD"
                accessCode={code}
              />
            </Title>
            <Search fields={announcementFilter} onSearch={onSubmitSearch} />
            <OneColumn>
              <Table
                heading={headingAnnouncement}
                data={announcement?.rows}
                onRowClick={onRowClick}
                onAction={onActionClick}
                havePagination={true}
                onPaginationChange={onPaginationChange}
                pageNumber={pageNumber}
                pageSize={pageSize}
                total={announcement?.count}
              />
            </OneColumn>
          </div>
        )
      default:
        break
    }
  }

  return <div>{renderFields()}</div>
}

export default MyAnnouncments
