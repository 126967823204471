import React from 'react'
import { PropTypes } from 'prop-types'
import styles from './input.module.scss'
import ErrorMessage from '../errorMessage'

const Input = ({
  onChange,
  stylesClass = 'defaultInput',
  globalClass = '',
  name,
  label,
  value,
  placeholder,
  disabled = false,
  id,
  errorTxt,
  type = 'text'
}) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-input`}>
      {label && <label>{label}</label>}
      <input
        className={styles.input}
        id={id ?? name}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Input
