import { useChangeForgotPasswordMutation } from 'api/authApiSlice'
import { BoxContainer } from 'components/containers/containers'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsLoadingData } from 'redux/reducers/staticSlice'
import FormForgotPassword from '../../components/forms/formForgotPassword'
import styles from './auth.module.scss'

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [changeForgotPassword] = useChangeForgotPasswordMutation()
  const navigate = useNavigate()

  const onSubmit = async data => {
    dispatch(setIsLoadingData(true))
    if (data) {
      await changeForgotPassword(data)
    }
    dispatch(setIsLoadingData(false))
    navigate('/')
  }

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        <FormForgotPassword onSubmit={onSubmit} />
      </BoxContainer>
    </div>
  )
}
export default ResetPassword
