/** Form for  add/edit/remove  'Oglas' */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDeleteOneMutation, useGetAllQuery } from 'api/dataApiSlice'
import Button from 'components/button'
import Checkbox from 'components/checkbox'
import DatePickerComp from 'components/dataPicker'
import YearPickerComp from 'components/dataPicker/yearPicker'
import ErrorMessage from 'components/errorMessage'
import Input from 'components/input'
import { Loader } from 'components/loader'
import ModalYesNo from 'components/modal/modalYesNo'
import { ImagesModal } from 'components/previewImageAnnoucment'
import RadioInput from 'components/radioInput'
import SelectInput from 'components/selectInput'
import TextArea from 'components/textarea'
import Title from 'components/title'
import UploadMedia from 'components/uploadMedia'
import { conditionalMapAnnouncement } from 'constants/conditionalFields'
import { dateFormat } from 'constants/date'
import { initialAnnouncement } from 'constants/initialValues'
import { label } from 'constants/labels'
import dayjs from 'dayjs'
import { queryGetAllFca, queryGetAllRealizations } from 'query/queryAnnouncment'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllContactsByClientId } from 'query/queryClientConectionTables'
import { queryGetAllCurrency } from 'query/queryCurrency'
import { queryDeleteImageAnnoucmentCategory, queryGetAllImageAnnoucmentCategoryByAnnoucmentId } from 'query/queryImageAnnoucmentCategory'
import { queryGetAllMeasureUnitProductsSearch } from 'query/queryMeasureUnitProduct'
import { queryGetAllProductDesc } from 'query/queryProductDescription'
import { queryGetAllProducts } from 'query/queryProducts'
import { queryGetAllRegion } from 'query/queryRegion'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { components } from 'react-select'
import { closeModal, openModal } from 'redux/reducers/modalSlice'
import { setUploadImages } from 'redux/reducers/staticSlice'
import { formatNumber, getCurrentTime, isObjectEmpty } from 'utils'
import { PAYMENT_TYPE } from '../../../constants/staticOptions'
import { errAnnouncement as errorMsg } from '../helpers/errorMsg'
import { getDiffForImageObject, prepareForUpload, pullConditionalFields } from '../helpers/utils'
import { validateAnnouncement as valdateForm } from '../helpers/validate'
import styles from './form.module.scss'

// const CustomOptionSelect = props => {
//   const { innerProps, data } = props
//   return (
//     <div {...innerProps} className="customOption">
//       <span>
//         {data?.ime} ({data?.mobilni})
//       </span>
//     </div>
//   )
// }
// const MultiValue = props => {
//   const { data } = props
//   return (
//     <components.MultiValue {...props}>
//       <span>
//         {data?.ime} ({data?.mobilni})
//       </span>
//     </components.MultiValue>
//   )
// }
// const CustomOptionSelectCity = props => {
//   const { innerProps, data } = props
//   return (
//     <div {...innerProps} className="customOption">
//       <span>
//         {data?.naziv},{data?.region?.naziv}
//       </span>
//     </div>
//   )
// }
// const MultiValueCity = props => {
//   const { data } = props
//   return (
//     <components.MultiValue {...props}>
//       <div className={styles.customSelect}>
//         {data?.naziv},{data?.region?.naziv}
//       </div>
//     </components.MultiValue>
//   )
// }
const FormAnnouncementModal = ({ existingData = {}, onSubmit, openRealizations, goBack, openOkModal }) => {
  const dispatch = useDispatch()
  const id = useSelector(state => state.auth.id)
  const isLoading = useSelector(state => state.static.isLoading)
  const [deleteOne] = useDeleteOneMutation()
  const [error, setError] = useState(true)
  const [data, setData] = useState({ ...initialAnnouncement })
  const [fca, setFca] = useState([])
  const [showRealization, setShowRealization] = useState(false)
  const [filterMap, setFilterMap] = useState({})
  const images = useSelector(state => state.static.images)
  const { data: cities = [] } = useGetAllQuery(queryGetAllCity())
  const { data: regions = [] } = useGetAllQuery(queryGetAllRegion())
  const { data: products = [] } = useGetAllQuery(queryGetAllProducts())
  const { data: productsDesc = [] } = useGetAllQuery(queryGetAllProductDesc())
  const { data: measureUnits = [] } = useGetAllQuery(
    queryGetAllMeasureUnitProductsSearch({ where: { proizvodId: data.proizvodId }, skip: data.proizvodId })
  )
  const { data: currencies = [] } = useGetAllQuery(queryGetAllCurrency())
  const { data: relizations = [] } = useGetAllQuery(
    queryGetAllRealizations(
      {
        sqlType: {
          OR: [{ objavaKupcaId: existingData.id }, { objavaProdavcaId: existingData.id }]
        }
      },
      {
        skip: !existingData.id
      }
    )
  )
  const { data: existingImageAnnocumentCategory = [] } = useGetAllQuery(queryGetAllImageAnnoucmentCategoryByAnnoucmentId(existingData.id), {
    skip: !existingData.id
  })
  const { data: clientContacts = [] } = useGetAllQuery(queryGetAllContactsByClientId({ where: { klijentId: id, kontaktOglas: true } }))
  const { data: fcaExisting = [] } = useGetAllQuery(queryGetAllFca({ where: { objavaId: existingData.id } }), {
    skip: !existingData.id,
    refetchOnMountOrArgChange: true
  })

  useEffect(() => {
    if (fcaExisting && existingData.id) setFca(fcaExisting)
  }, [fcaExisting])

  useEffect(() => {
    if (!isObjectEmpty(existingData)) {
      setData(prev => ({
        ...prev,
        ...existingData,
        cena: formatNumber(existingData?.cena, 'twoDecimals'),
        kolicina: formatNumber(existingData?.kolicina, 'twoDecimals')
      }))
    }
  }, [existingData])

  useEffect(() => {
    if (!data.id) {
      if (data.tip === 1) {
        setData(prev => ({ ...prev, cenaVidljiva: true }))
      }
    } else {
      if (data.tip === 2) {
        setData(prev => ({
          ...prev,
          cenaVidljiva: existingData.cenaVidljiva,
          connectingTables: {
            fcaDelete: fcaExisting
          }
        }))
      }
    }
  }, [data.tip])

  useEffect(() => {
    if (existingImageAnnocumentCategory && existingData.id) {
      dispatch(setUploadImages(existingImageAnnocumentCategory))
    }
  }, [existingData.id, existingImageAnnocumentCategory])

  const handleSubmit = async isNew => {
    const foundProduct = products.find(e => data.proizvodId === e.id)
    const reqData = {
      tip: data.tip,
      napomena: data.napomena,
      cena: data.cena ? +data.cena : null,
      kolicina: data.kolicina,
      status: existingData && Object.keys(existingData).length ? data.status : 1,
      rod: data.rod,
      proizvodId: data.proizvodId,
      opisProizvodaId: data.opisProizvodaId,
      unetaJedinicaMereId: data.unetaJedinicaMereId,
      unetaCenaJedinicaMereId: data.unetaCenaJedinicaMereId,
      podrazumevanaJedinicaMereId: foundProduct?.jedinicaMereId,
      podrazumevanaCenaJedinicaMereId: foundProduct?.cenaJedinicaMereId,
      podrazumevanValutaId: foundProduct?.valutaId,
      // podrazumevanaJedinicaMereId: data.podrazumevanaJedinicaMereId,
      // podrazumevanaCenaJedinicaMereId: data.podrazumevanaCenaJedinicaMereId,
      // podrazumevanValutaId: data.podrazumevanValutaId,
      valutaId: data.valutaId,
      klijentId: id,
      kontakti: data.kontakti,
      datumVazenja: data.datumVazenja,
      tipPlacanja: data.tipPlacanja,
      kretanjeCena: data.kretanjeCena,
      robniIzvestaj: data.robniIzvestaj,
      cenaVidljiva: data.cenaVidljiva,
      oglasVidljiv: data.oglasVidljiv,
      pdv: data.pdv,
      fcaDogovor: data.fcaDogovor,
      fcaKupac: data.fcaKupac,
      connectingTables: {
        fcaCreate: [],
        fcaDelete: []
      }
    }
    if (isNew) reqData.connectingTables.fcaCreate = fca
    else {
      let deleteBuy = []
      let createBuy = []
      if (data.tip === 1) {
        deleteBuy = fcaExisting.filter(x => !fca.some(y => y.gradId === x.gradId))
        createBuy = fca.filter(x => !fcaExisting.some(y => y.gradId === x.gradId))
      } else if (data.tip === 2) {
        deleteBuy = fcaExisting.filter(x => !fca.some(y => y.regionId === x.regionId && !x.gradId))
        createBuy = fca.filter(x => !fcaExisting.some(y => y.regionId === x.regionId && !y.gradId))
      }
      reqData.connectingTables.fcaCreate = createBuy
      reqData.connectingTables.fcaDelete = deleteBuy
    }
    if (data.id) reqData.id = data.id
    if (isNew) delete reqData.id
    setData(reqData)
    if (valdateForm(reqData, fcaExisting)) {
      setError(false)
      let fileIds = []
      if (reqData.id) {
        const diff = getDiffForImageObject(existingImageAnnocumentCategory, images)
        fileIds = await prepareForUpload(diff)
      } else {
        fileIds = await prepareForUpload(images)
      }
      onSubmit(reqData, fileIds)
      //   refetch()
    } else setError(true)
  }

  const handleChange = e => {
    const { name, value } = e.target
    setData(formData => ({ ...formData, [name]: value }))
  }

  const onSelectChange = async (e, name, conditional, isMultiselect, key = 'id') => {
    if (isMultiselect) {
      if (name === 'region') {
        const newFca = e.map(x => {
          return { drzavaId: x.drzavaId, regionId: x.id }
        })
        setFca(newFca)
      } else if (name === 'grad') {
        const newFca = e.map(x => {
          return { drzavaId: x.drzavaId, regionId: x.regionId, gradId: x.id }
        })
        setFca(newFca)
      } else {
        setData(prev => ({ ...prev, [name]: e }))
      }
    } else {
      if (conditional) {
        await getOptions(conditional, e)
      } else {
        setData(prev => ({ ...prev, [name]: e[key] ? e[key] : e?.id ?? null }))
      }
    }
    if (name === 'klijentId') {
      setData(prev => ({ ...prev, kontakti: [] }))
    }
    if (name === 'opisProizvoda') {
      const product = products.find(e => e.id === e.proizvodId)
      setProducts(product)
    }
    if (name === 'proizvodId') {
      setProducts(e)
    }
  }
  const setProducts = e => {
    setData(prev => ({
      ...prev,
      unetaJedinicaMereId: e.jedinicaMereId,
      valutaId: e.valutaId,
      unetaCenaJedinicaMereId: e.cenaJedinicaMereId
      // podrazumevanaJedinicaMereId: e.jedinicaMereId,
      // podrazumevanValutaId: e.valutaId,
      // podrazumevanaCenaJedinicaMereId: e.cenaJedinicaMereId
    }))
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    if (name === 'fcaKupac') {
      if (checked) {
        setFca([])
        setData(prev => ({ ...prev, fcaDogovor: false, fcaKupac: checked }))
      }
    } else if (name === 'fcaDogovor') {
      if (checked) {
        setFca([])
        setData(prev => ({ ...prev, fcaDogovor: checked, fcaKupac: false }))
      }
    }
    setData(prev => ({ ...prev, [name]: checked ?? null }))
  }

  const onDateChange = (date, name) => {
    setData(formData => ({ ...formData, [name]: date ? getCurrentTime(date) : null }))
  }
  const onRadioChange = type => {
    setData(prev => ({ ...prev, tip: type }))
    setFca([])
    if (type === 2 && !data.id) setData(prev => ({ ...prev, fcaDogovor: true, fcaKupac: false }))
  }

  const getOptions = async (mapName, selectValue) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapAnnouncement, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
  }

  const onImageChange = images => {
    dispatch(setUploadImages(images))
  }

  const handleDelete = async (answer, data) => {
    if (answer) {
      const res = await deleteOne(queryDeleteImageAnnoucmentCategory(data.id))
      if (!res.error) dispatch(closeModal(2))
    } else {
      dispatch(closeModal(2))
    }
  }

  const onImagePreview = () => {
    if (images[0].link) {
      dispatch(
        openModal({
          arrItem: {
            content: <ImagesModal src={images[0]?.link} imgs={images} modalPosition={3} />,
            order: 2,
            size: 'lg'
          },
          active: 2
        })
      )
    }
  }

  const removeUploadedImage = index => {
    if (!(images[index] instanceof File)) {
      dispatch(
        openModal({
          arrItem: {
            content: <ModalYesNo handleSubmit={handleDelete} data={images[index]} question={label.msgDeleteImage} />,
            order: 2,
            size: 'sm'
          },
          active: 2
        })
      )
    } else {
      const newImages = images.filter((e, i) => i !== index)
      dispatch(setUploadImages(newImages))
    }
  }
  return (
    <div className={styles.wrapper}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      )}
      <div>
        <Title
          title={existingData && Object.keys(existingData).length ? 'Azuriraj oglas' : 'Dodaj oglas'}
          globalClass="mb-2"
          goBackClick={() => goBack()}
        >
          {existingData && Object.keys(existingData).length && (
            <Button onClick={() => openOkModal(existingData)} label={label.delete} iconLeft={<FontAwesomeIcon icon="fa-trash-can" />} />
          )}
        </Title>
        <div className={styles.radioGroup}>
          <p>{label.bidDemandInput}:</p>
          <div className={styles.radioGroupInputs}>
            <RadioInput globalClass="mb-0" name="tip" label={label.bidSupply} checked={data.tip === 1} onChange={() => onRadioChange(1)} />
            <RadioInput
              name="tip"
              globalClass="mb-0 mx-2"
              label={label.bidDemand}
              checked={data.tip === 2}
              onChange={() => onRadioChange(2)}
            />

            {!data.tip && error && <ErrorMessage message={label.bidDemandErr} />}
          </div>
        </div>
        <div className={`${products && data.proizvodId && products.find(e => data.proizvodId === e.id && e.rod) ? styles.row : ''}`}>
          <SelectInput
            options={products || []}
            filter={filterMap?.products}
            handleChangeSelect={e => onSelectChange(e, 'proizvodId', 'proizvod')}
            selectedOption={products.find(el => el.id === data.proizvodId || null)}
            customValue="id"
            customLabel="naziv"
            label={label.products}
            errorTxt={error && !data.proizvodId && errorMsg('proizvodId')}
          />
          {products && data.proizvodId && products.find(e => data.proizvodId === e.id && e.rod) && (
            <YearPickerComp
              name="rod"
              startDate={data.rod ? new Date(data.rod) : null}
              onChange={date => setData(prev => ({ ...prev, rod: new Date(date) }))}
              label={label.categoryInputRod}
            />
          )}
        </div>
        <SelectInput
          options={productsDesc || []}
          filter={filterMap?.productsDesc}
          handleChangeSelect={e => onSelectChange(e, 'opisProizvodaId', 'opisProizvoda')}
          selectedOption={productsDesc.find(el => el.id === data.opisProizvodaId || null)}
          customValue="id"
          customLabel="naziv"
          label={label.productsDescInput}
          //  errorTxt={error && !data.opisProizvodaId && errorMsg('opisProizvodaId', { productsDesc })}
        />

        <div className={styles.row}>
          <Input
            name="kolicina"
            label={label.amountEnteredInput}
            placeholder={label.placeholder}
            value={data.kolicina}
            onChange={handleChange}
            errorTxt={error && !data.kolicina && errorMsg('kolicina', data)}
            type="number"
          />
          <SelectInput
            options={measureUnits}
            handleChangeSelect={e => onSelectChange(e, 'unetaJedinicaMereId', false, false, 'jedinicaMereId')}
            selectedOption={measureUnits.find(el => el.jedinicaMereId === data.unetaJedinicaMereId || null)}
            customValue="jedinicaMereId"
            customLabel="jmNaziv"
            label={label.measureUnitsInput}
            errorTxt={error && !data.unetaJedinicaMereId && errorMsg('unetaJedinicaMereId')}
          />
        </div>

        <div className={`${styles.row} ${styles.price}`}>
          <Input
            name="cena"
            label={label.priceInput}
            placeholder="00.00"
            value={data.cena}
            onChange={handleChange}
            errorTxt={error && data.tip === 1 && !data.cena && errorMsg('cena', data)}
            type="number"
          />
          <SelectInput
            options={currencies}
            handleChangeSelect={e => onSelectChange(e, 'valutaId')}
            selectedOption={currencies.find(el => el.id === data.valutaId || null)}
            customValue="id"
            customLabel="kod"
            label={label.currency}
            errorTxt={error && data.tip === 1 && !data.valutaId && errorMsg('valutaId')}
          />
          <div className={styles.between}>/</div>
          <SelectInput
            options={measureUnits}
            handleChangeSelect={e => onSelectChange(e, 'unetaCenaJedinicaMereId', false, false, 'jedinicaMereId')}
            selectedOption={measureUnits.find(el => el.jedinicaMereId === data.unetaCenaJedinicaMereId || null)}
            customValue="jedinicaMereId"
            customLabel="jmNaziv"
            label={label.measureUnitsInput}
            errorTxt={error && data.tip === 1 && !data.unetaCenaJedinicaMereId && errorMsg('cenaJedinicaMereId')}
          />
        </div>
        <Checkbox name="pdv" label={`+${label.pdv}`} checked={data.pdv} onChange={handleCheckboxChange} />
        <SelectInput
          options={PAYMENT_TYPE}
          handleChangeSelect={e => onSelectChange(e, 'tipPlacanja')}
          selectedOption={PAYMENT_TYPE.find(el => el.id === data.tipPlacanja || null)}
          customValue="id"
          customLabel="label"
          label={label.paymentType}
          errorTxt={error && !data.tipPlacanja && errorMsg('tipPlacanja')}
        />

        {data.tip === 1 && (
          <div className={`${data.fcaKupac ? '' : styles.row} ${styles.fca}`}>
            {!data.fcaKupac && (
              <SelectInput
                options={cities || []}
                handleChangeSelect={e => onSelectChange(e, 'grad', false, true)}
                selectedOption={cities.length && fca.length ? cities.filter(x => fca.some(y => y.gradId === x.id)) : null}
                customValue="id"
                customLabel="naziv"
                label={label.francoCity}
                multiselect={true}
                isClearable={false}
                errorTxt={error && !fca.length && errorMsg('utovar')}
                // customComponents={{ Option: CustomOptionSelectCity, MultiValue: MultiValueCity }}
              />
            )}
            <Checkbox name="fcaKupac" label={label.fca3} checked={data.fcaKupac} onChange={handleCheckboxChange} globalClass="mt-3 " />
          </div>
        )}
        {data.tip === 2 && (
          <div className={styles.border}>
            <div className={styles.row}>
              <Checkbox name="fcaDogovor" label={label.fca2} checked={data.fcaDogovor} onChange={handleCheckboxChange} globalClass="mt-1" />
              <Checkbox name="fcaKupac" label={label.fca3} checked={data.fcaKupac} onChange={handleCheckboxChange} globalClass="mt-1" />
            </div>
            {!data.fcaKupac && !data.fcaDogovor && (
              <SelectInput
                options={regions || []}
                handleChangeSelect={e => onSelectChange(e, 'region', false, true)}
                selectedOption={regions.length && fca.length ? regions.filter(x => fca.some(y => y.regionId === x.id)) : null}
                customValue="id"
                customLabel="naziv"
                label={label.francoRegion}
                multiselect={true}
                isClearable={false}
                errorTxt={error && !fca.length && errorMsg('utovar')}
              />
            )}
          </div>
        )}
        <SelectInput
          options={clientContacts || []}
          handleChangeSelect={e => onSelectChange(e, 'kontakti', false, true)}
          selectedOption={data.kontakti || []}
          customValue="id"
          customLabel="ime"
          label={label.contactPersonsInput}
          multiselect={true}
          isClearable={false}
          // customComponents={{ Option: CustomOptionSelect, MultiValue }}
          errorTxt={error && !data.kontakti.length && errorMsg('kontakti')}
        />
        <TextArea
          rows={4}
          name="napomena"
          label={label.noteInput}
          placeholder={label.placeholder}
          value={data.napomena}
          onChange={handleChange}
        />
        {/* {data.tip === 1 && (
          <>
            <Checkbox
              name="kretanjeCena"
              label={label.priceMoving}
              checked={data.kretanjeCena}
              onChange={handleCheckboxChange}
              globalClass="mt-2"
            />
            <Checkbox
              name="robniIzvestaj"
              label={label.goodsReportLabel}
              checked={data.robniIzvestaj}
              onChange={handleCheckboxChange}
              globalClass="mt-2"
            />
          </>
        )} */}
        <Checkbox
          name="cenaVidljiva"
          label={label.priceVisible}
          checked={data.cenaVidljiva}
          onChange={handleCheckboxChange}
          globalClass="mt-2"
        />
        {/* <Checkbox
          name="oglasVidljiv"
          label={label.addVisible}
          checked={data.oglasVidljiv}
          onChange={handleCheckboxChange}
          globalClass="mt-2"
        /> */}
        <DatePickerComp
          startDate={data.datumVazenja ? new Date(data.datumVazenja) : null}
          onChange={date => onDateChange(date, 'datumVazenja')}
          label={label.dateOfTerm}
          errorTxt={error && !data.datumVazenja && errorMsg('datumVazenja')}
        />
        {data.id && (
          <>
            <Input name="createdAt" label={label.dateOfEntering} value={dayjs(data.createdAt).format(dateFormat)} disabled={true} />
            <div className={styles.realization}>
              <Checkbox
                name="realizovano"
                label={label.realization}
                checked={relizations.length || data.showRealization}
                onChange={e => setShowRealization(relizations.length ? true : e.target.checked)}
                globalClass="mt-2 mb-0"
              />
              {(relizations.length || showRealization) && (
                <b onClick={() => openRealizations(data)}>Dodaj realizaciju i pregledaj postojece</b>
              )}
            </div>
          </>
        )}

        <UploadMedia
          onImageChange={onImageChange}
          images={images}
          removeUploadedImage={index => removeUploadedImage(index)}
          onImagePreview={onImagePreview}
          label="Dodajte slike za oglase"
        />
        <div className={styles.btns}>
          {data.id ? (
            <div className={styles.second}>
              <Button
                label={label.refreshAdd}
                iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                onClick={() => handleSubmit(false)}
                stylesClass="btnWarning"
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          ) : (
            <div className={styles.second}>
              <Button
                label={label.saveAsNewAdd}
                iconLeft={<FontAwesomeIcon icon="fa-floppy-disk" />}
                stylesClass="btnWarning"
                onClick={() => handleSubmit(true)}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default FormAnnouncementModal
