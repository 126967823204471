import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styles from './web.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { logOut, selectCurrentUser } from 'redux/reducers/authSlice'
import logoImg from 'assets/logo/logo.png'
import SocialMedia from '../socilaMedia'

const TopNavbar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const user = useSelector(selectCurrentUser)
  const logoutUser = async () => {
    await dispatch(logOut())
    navigate('/', { replace: true })
  }
  return (
    <div className={styles.topNavWrapper}>
      <SocialMedia />
      <ul>
        <li>
          <NavLink to="not-found">Pocetna</NavLink>
        </li>
        <li>
          <NavLink to="not-found">Usluge</NavLink>
        </li>
        <li className={styles.topDropdown}>
          <div className={styles.dropContent}>
            <span>O nama</span>
            <ul className={styles.dropdown}>
              <li>
                <NavLink to="not-found">Kontakt</NavLink>
              </li>
              <li>
                <NavLink to="not-found">Nasi partneri</NavLink>
              </li>
              <li>
                <NavLink to="not-found">Reference</NavLink>
              </li>
              <li>
                <NavLink to="not-found">Desavanja</NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <NavLink to="not-found">Najčešća pitanja</NavLink>
        </li>
        <li>
          <NavLink to="not-found">Aplikacije</NavLink>
        </li>
        {token ? (
          <li className={styles.topDropdown}>
            <div className={styles.dropContent}>
              <span>{user?.naziv}</span> <FontAwesomeIcon icon="fa-circle-user" />
              <ul className={`${styles.dropdown} ${styles.dropUser}`}>
                <li>
                  <NavLink to="not-found">Prethodni oglasi</NavLink>
                </li>
                <li>
                  <NavLink to="/moji-oglasi">Moji oglasi</NavLink>
                </li>
                <li>
                  <NavLink to="not-found">Roba koju pratim</NavLink>
                </li>
                <li>
                  {token && (
                    <Button
                      onClick={logoutUser}
                      label="Odjavi se"
                      iconRight={<FontAwesomeIcon icon="fa-sign-out" />}
                      globalClass="mt-2 w-100"
                    />
                  )}
                </li>
              </ul>
            </div>
          </li>
        ) : (
          <li>
            <div className={styles.btnGroup}>
              <Button
                stylesClass="btnLight"
                onClick={() => navigate('login')}
                label="Prijavi se"
                iconRight={<FontAwesomeIcon icon="fa-sign-out" />}
                globalClass="w-100"
              />
              <Button
                stylesClass="btnSecondary"
                onClick={() => navigate('register')}
                label="Registracija"
                iconRight={<FontAwesomeIcon icon="fa-sign-out" />}
                globalClass="w-100 ml-3"
              />
            </div>
          </li>
        )}
      </ul>
      {/* <Button onClick={logoutUser} label="Odjavi se" iconRight={<FontAwesomeIcon icon="fa-sign-out" />} globalClass="mt-2 w-100" /> */}
    </div>
  )
}
const BottomNavbar = () => {
  return (
    <div className={styles.bottomNavbar}>
      <NavLink to="/">
        <img src={logoImg} />
      </NavLink>
      <ul>
        <li>
          <NavLink to="dnevni-oglasi">Dnevni oglasi</NavLink>
        </li>
        <li>
          <NavLink to="kretanje-cena">Kretanje cena</NavLink>
        </li>
        <li>
          <NavLink to="uvoz-izvoz">Uvoz/Izvoz</NavLink>
        </li>
        <li>
          <NavLink to="berze">Berze</NavLink>
        </li>
        <li>
          <NavLink to="basic">Adresar</NavLink>
        </li>
        <li>
          <NavLink to="basic">Subvencije i regulative</NavLink>
        </li>
        <li>
          <NavLink to="basic">Aktuelnosti</NavLink>
        </li>
        <li>
          <NavLink to="basic">Oglasi sa slikom</NavLink>
        </li>
      </ul>
    </div>
  )
}
const WebNavbar = () => {
  return (
    <div className={styles.webNav}>
      <div className={styles.firstBlock}>
        <TopNavbar />
      </div>
      <div className={styles.secondBlock}>
        <BottomNavbar />
      </div>
    </div>
  )
}

export default WebNavbar
