import { createSlice } from '@reduxjs/toolkit'
const staticSlice = createSlice({
  name: 'static',
  initialState: {
    images: [],
    isLoading: false,
    isDisabled: false,
    selectedCountry: {},
    multipleRequiredFields: {},
    multipleValidArr: [],
  },
  reducers: {
    setUploadImages: (state, action) => {
      state.images = action.payload
    },
    setIsLoadingData: (state, action) => {
      state.isLoading = action.payload
    },
    setIsDisabled: (state, action) => {
      state.isDisabled = action.payload
    },
    getMultipleRequiredFields: (state, action) => {
      state.multipleRequiredFields = { ...state.multipleRequiredFields, ...action.payload }
    },
    arrMutiFieldsValid: (state, action) => {
      state.multipleValidArr = [...state.multipleValidArr, action.payload]
    }
  }
})

export const { setUploadImages, setIsLoadingData, setIsDisabled, getMultipleRequiredFields, arrMutiFieldsValid } = staticSlice.actions

export default staticSlice.reducer
