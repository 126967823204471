export const tag = {
  roles: 'ROLES',
  categories: 'CATEGORIES',
  items: 'ITEMS',
  ips: 'IPS',
  users: 'USERS',
  entityTypes: 'ENTITY_TYPES',
  subBusinessActivity: 'SUB_BUSINESS_ACTIVITY',
  businessActivity: 'BUSINESS_ACTIVITY',
  region: 'REGION',
  city: 'CITY',
  country: 'COUNTRY',
  clients: 'CLIENTS',
  client: 'CLIENT',
  clientActivity: 'CLIENT_ACTIVITY',
  clientContacts: 'CLIENT_CONTACTS',
  workField: 'WORK_FIELD',
  currency: 'CURRENCY',
  imageAnnoucmentCategory: 'IMAGE_ANNOUCMENT_CATEGORY',
  productDescription: 'PRODUCT_DESCRIPTION',
  products: 'PRODUCTS',
  announcementDemand: 'ANNOUNCMENT_DEMAND',
  announcementSupplay: 'ANNOUNCMENT_SUPPLAY',
  announcement: 'ANNOUNCMENT',
  oneAnnouncement: 'ONE_ANNOUNCMENT',
  announcementFca: 'ANNOUNCMENT_FCA',
  realizations: 'REALIZATIONS',
  measureUnitProduct: 'MEASURE_UNIT_PRODUCT'
}
export const tagArray = [
  tag.roles,
  tag.categories,
  tag.items,
  tag.users,
  tag.entityTypes,
  tag.businessActivity,
  tag.subBusinessActivity,
  tag.country,
  tag.region,
  tag.city,
  tag.client,
  tag.clients,
  tag.clientActivity,
  tag.clientContacts,
  tag.workField,
  tag.currency,
  tag.imageAnnoucmentCategory,
  tag.productDescription,
  tag.products,
  tag.announcementDemand,
  tag.announcementSupplay,
  tag.announcement,
  tag.oneAnnouncement,
  tag.announcementFca,
  tag.realizations,
  tag.measureUnitProduct
]
