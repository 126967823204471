import React from 'react'
import PropTypes from 'prop-types'
import styles from './textarea.module.scss'
import ErrorMessage from '../errorMessage'

const TextArea = ({
  onChange,
  stylesClass = 'textareaDefault',
  globalClass = '',
  name,
  label,
  value,
  placeholder,
  disabled = false,
  rows = 5,
  errorTxt
}) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-textarea`}>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        className={styles.textarea}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        readOnly={disabled}
        rows={rows}
      />
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

TextArea.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default TextArea
