import { FormGroup } from 'components/containers/containers'
import Input from 'components/input'
import MultipleInputs from 'components/inputMultiple'
import PhoneInputComp from 'components/phoneInput'
import SelectInput from 'components/selectInput'
import { multiFieldsDelatnostInitial } from 'constants/initialValues'
import { CLIENT_CONNECTION_TB } from 'constants/other'
import { errClientRegister as errorMsg } from '../../components/forms/helpers/errorMsg'
// import ListMessages from 'components/listMessages'
import InputPassword from 'components/inputPassword'

const FormClientsLegal = ({
  data,
  handleSelectChange,
  handleChange,
  onAddField,
  onMultipleChange,
  onRemoveMultiselect,
  // onRemarkChange,
  // onRemarkEdit,
  // remarkList = [],
  handlePhoneChange,
  error,
  multiErrorRow,
  filterMap = {},

  countries = [],
  cities = [],
  region = [],

  isDomestic,
  multiFieldsContactsInitial,
  multiDataList
}) => {
  return (
    <div>
      <Input
        className="form-control"
        name="pib"
        value={data.pib}
        label="PIB:"
        onChange={handleChange}
        placeholder="pib..."
        errorTxt={error && errorMsg('pib', data)}
        globalClass="mb-2"
      />
      <Input
        className="form-control"
        name="naziv"
        value={data.naziv}
        label="Naziv firme:"
        onChange={handleChange}
        placeholder="naziv firme..."
        errorTxt={error && !data.naziv && 'Naziv firme je obavezno polje'}
        globalClass="mb-2"
      />
      <SelectInput
        options={countries || []}
        filter={filterMap?.country}
        handleChangeSelect={e => handleSelectChange(e, 'drzavaId', 'drzava')}
        selectedOption={countries.find(el => el.id === data.drzavaId) || null}
        customLabel="naziv"
        customValue="id"
        label="Drzava"
        errorTxt={error && !data.drzavaId && 'Drzava je obavezno polje'}
      />
      <SelectInput
        options={cities || []}
        filter={filterMap?.city}
        handleChangeSelect={e => handleSelectChange(e, 'gradId', 'grad')}
        selectedOption={cities.find(el => el.id === data.gradId) || null}
        customLabel="naziv"
        customValue="id"
        label="Grad"
        errorTxt={error && !data.gradId && 'Grad je obavezno polje'}
      />
      <SelectInput
        options={region || []}
        filter={filterMap?.region}
        handleChangeSelect={e => handleSelectChange(e, 'regionId', 'region')}
        selectedOption={region.find(el => el.id === data.regionId) || null}
        customLabel="naziv"
        customValue="id"
        label="Region"
        errorTxt={error && isDomestic && !data.regionId && 'Region je obavezno polje'}
      />
      <FormGroup label="DELATNOST I PODDELTNOST">
        <MultipleInputs
          name={CLIENT_CONNECTION_TB.activity}
          fields={multiFieldsDelatnostInitial}
          data={multiDataList[CLIENT_CONNECTION_TB.activity]}
          onAdd={onAddField}
          onChange={onMultipleChange}
          onRemove={onRemoveMultiselect}
          label="Dodaj novu delatnost i poddelatnost"
          stylesClass="multiTwoFields"
          errorGroup={error && !multiDataList[CLIENT_CONNECTION_TB.activity].length && 'Minimum jedna delatnost i podelatnost obavezna'}
          errorRow={multiErrorRow}
        />
      </FormGroup>
      <PhoneInputComp
        value={data.kontaktTelefon}
        label="Kontakt telefon firme:"
        onChange={phone => handlePhoneChange('kontaktTelefon', phone)}
        errorTxt={error && !data.kontaktTelefon && 'Kontakt telefon je obavezno polje'}
      />
      <Input
        className="form-control"
        name="direktor"
        value={data.direktor}
        label="Ime zastupnika:"
        onChange={handleChange}
        placeholder="ime zastupnika..."
        errorTxt={error && !data.direktor && 'Ime zastupnika je obavezno polje'}
        globalClass="mb-2"
      />
      <FormGroup label="KONTAKT OSOBA">
        <MultipleInputs
          name={CLIENT_CONNECTION_TB.contact}
          fields={multiFieldsContactsInitial}
          data={multiDataList[CLIENT_CONNECTION_TB.contact]}
          onAdd={onAddField}
          onChange={onMultipleChange}
          onRemove={onRemoveMultiselect}
          label="Dodaj novu kontakt osobu"
          stylesClass="multiTwoFields"
          errorGroup={error && !multiDataList[CLIENT_CONNECTION_TB.contact].length && 'Minimum jedna kontakt osoba je obavezna'}
          errorRow={multiErrorRow}
        />
      </FormGroup>
      <Input
        className="form-control"
        name="email"
        value={data.email}
        label="Email:"
        onChange={handleChange}
        placeholder="Email..."
        errorTxt={error && errorMsg('email', data)}
        globalClass="mb-2"
      />
      <Input
        className="form-control"
        name="username"
        value={data.username}
        label="Korisničko ime:"
        onChange={handleChange}
        placeholder="Korisničko ime..."
        errorTxt={error && !data.username && 'Korisničko ime je obavezno polje'}
        globalClass="mb-2"
      />
      <InputPassword
        className="form-control"
        name="lozinka"
        value={data.lozinka}
        label="Lozinka:"
        onChange={handleChange}
        placeholder="Lozinka..."
        errorTxt={error && errorMsg('lozinka', data)}
        globalClass="mb-2"
      />
      <InputPassword
        className="form-control"
        name="lozinkaPotvrda"
        value={data.lozinkaPotvrda}
        label="Potvrdi lozinku"
        onChange={handleChange}
        placeholder="Potvrdi lozinku ......"
        errorTxt={error && errorMsg('lozinkaPotvrda', data)}
        globalClass="mb-2"
      />
      <Input
        className="form-control"
        name="sajt"
        value={data.sajt}
        label="Sajt:"
        onChange={handleChange}
        placeholder="web..."
        errorTxt={error && !data.sajt && 'Sajt je obavezno polje'}
        globalClass="mb-2"
      />
    </div>
  )
}

export default FormClientsLegal
