import { BoxContainer } from 'components/containers/containers'

const NotFound = () => {
  return (
    <BoxContainer globalClass="m-5 p-2">
      <h2>Not found</h2>
    </BoxContainer>
  )
}

export default NotFound
