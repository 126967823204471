import React from 'react'
import { PropTypes } from 'prop-types'
import ErrorMessage from '../errorMessage'
import styles from './radioInput.module.scss'

const RadioInput = ({ onChange, stylesClass = 'defaultRadio', globalClass = '', name, label, checked, disabled, id, errorTxt }) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-radio`}>
      <label>
        <input type="radio" name={name} id={id ?? name} checked={checked} onChange={onChange} disabled={disabled} />
        <span>{label}</span>
        <span className={styles.checkmark}></span>
      </label>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

RadioInput.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default RadioInput
