import React from 'react'
import styles from './landing.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useWindowDimensions from 'hooks/onWindowResize'

const Landing = () => {
  const { isMobile } = useWindowDimensions()

  return (
    <div className={styles.wrapper}>
      {isMobile ? (
        <div className={styles.mobHeading}>
          <div>
            <FontAwesomeIcon icon="fa-list-ul" />
            <h3>Dnevni oglasi</h3>
          </div>
          <div>
            <FontAwesomeIcon icon="fa-arrow-trend-up" />
            <h3>Kretanje cena</h3>
          </div>
          <div>
            <FontAwesomeIcon icon="fa-solid fa-landmark" />
            <h3>Berze</h3>
          </div>
          <div>
            <FontAwesomeIcon icon="fa-arrow-right-arrow-left" />
            <h3>Uvoz/izvoz</h3>
          </div>
        </div>
      ) : (
        <h1 className="mt-4">Web landing</h1>
      )}
    </div>
  )
}

export default Landing
