import Button from 'components/button'
import Checkbox from 'components/checkbox'
import { TwoColumns } from 'components/containers/containers'
import DatePickerComp from 'components/dataPicker'
import Input from 'components/input'
import InputPassword from 'components/inputPassword'
import RadioInput from 'components/radioInput'
import SelectInput from 'components/selectInput'
import SwitchBtn from 'components/switchBtn'
import Table from 'components/table'
import TextArea from 'components/textarea'
import Title from 'components/title'
import UploadMedia from 'components/uploadMedia'
import { calendarPreviewTimeFormat, dateFormat, timeFormat } from 'constants/date'
import { headingTest, headingTest2 } from 'constants/tbHeading'
import * as dayjs from 'dayjs'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { components } from 'react-select'
import { openModal } from 'redux/reducers/modalSlice'
import { setUploadImages } from 'redux/reducers/staticSlice'

import styles from './basic.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { SectionContainer } from '../../components/containers/containers'

const options = [
  { name: 'option1', id: 1, color: '#FF6B00' },
  { name: 'option2', id: 2, color: 'red' },
  { name: 'option3', id: 3, color: 'yellow' },
  { name: 'option4', id: 4, color: 'purple' }
]

const dataTb = [
  {
    firstName: 'Jelena',
    lastName: 'Ivezic',
    age: 36,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: [
      'https://www.infotim.rs/site/img/design/android.jpg1',
      'https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg',
      'https://www.infotim.rs/oglasi/269/thumbs/1559739136_1923382.jpg',
      'https://www.infotim.rs/oglasi/269/1559739108_1241016.jpg'
    ]
  },
  {
    firstName: 'Jelena2',
    lastName: 'Ivezic2',
    age: 320,
    time: new Date(),
    date: new Date(),
    isTrue: true,
    prodImg: ['https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg', 'https://www.infotim.rs/oglasi/269/1559739108_1241016.jpg']
  },
  {
    firstName: 'Jelena3',
    lastName: 'Ivezic3',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: ['https://www.infotim.rs/oglasi/269/thumbs/1559739136_1923382.jpg']
  },
  {
    firstName: 'Jelena4',
    lastName: 'Ivezic4',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false
  },
  {
    firstName: 'Jelena4',
    lastName: 'Ivezic4',
    age: 8,
    time: new Date(),
    date: new Date(),
    isTrue: false,
    prodImg: ['https://www.infotim.rs/oglasi/281/1585891300_2408781.jpg']
  }
]
const CustomOptionSelect = props => {
  const { innerProps, data } = props
  return (
    <div {...innerProps} className={styles.customSelect}>
      <div className={styles.customCircle} style={{ background: data.color || 'grey' }} />
      <span>{data.name}</span>
    </div>
  )
}
const SingleValue = props => {
  const { data } = props
  return (
    <components.SingleValue {...props}>
      <div className={styles.customSelect}>
        <div className={styles.customCircle} style={{ background: data.color || 'grey' }} />
        <span>{data.name}</span>
      </div>
    </components.SingleValue>
  )
}

const BasicComponents = () => {
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [testCheck, setTestCheck] = useState(true)
  const [testRadio, setTestRadio] = useState('option1')
  const [testSwitch, setTestSwitch] = useState(true)
  const [selected, setSelected] = useState(options[1])
  const [time, setTime] = useState()
  const [date, setDate] = useState()
  const images = useSelector(state => state.static.images)

  const showModal = () => {
    dispatch(openModal({ arrItem: { content: <div>Test modal</div>, order: 1, size: 'sm' }, active: 1 }))
  }

  const onSwitchChange = checked => {
    setTestSwitch(checked)
  }

  const onSelectChange = e => {
    setSelected(e)
  }

  const onDateChange = date => {
    const formatted = date ? dayjs(date).format(dateFormat) : ''
    setDate(formatted)
  }

  const onDateTimeChange = date => {
    const formatted = date ? dayjs(date).format(timeFormat) : ''
    setTime(formatted)
  }

  const onImageChange = images => {
    dispatch(setUploadImages(images))
  }

  return (
    <div className="bg-s p-2 br-xs">
      <Title title={'Pravno lice Srbija'} desc={'Lorem ipsum,Lorem ipsumLorem ipsum'}>
        <Button onClick={showModal} label="Add" stylesClass="btnSecondary" iconLeft={<FontAwesomeIcon icon="fa-plus" />} />
        <Button onClick={showModal} label="Test" />
        <Button onClick={showModal} label="Light" stylesClass="btnLight" />
      </Title>
      <TwoColumns>
        <div>
          <div>
            <h2>Icons</h2>
            <p>Import icons in file components/icons </p>
            <h4>
              Search icons
              <a className="c-b" href="https://fontawesome.com/search?m=free&o=r" target="_blank" rel="noreferrer">
                HERE
              </a>
            </h4>
            <h3>
              <FontAwesomeIcon icon="fa-dashboard" /> <small>"FontAwesomeIcon icon="fa-dashboard"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-gavel" /> <small>"FontAwesomeIcon icon="fa-gavel"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-dashboard" /> <small>"FontAwesomeIcon icon="fa-dashboard"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-money-bill-wheat" /> <small>"FontAwesomeIcon icon="fa-money-bill-wheat"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-circle-user" />
              <small>"FontAwesomeIcon icon="fa-circle-user"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-bars" /> <small>"FontAwesomeIcon icon="fa-bars"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-close" /> <small>"FontAwesomeIcon icon="fa-close"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sign-in" /> <small>"FontAwesomeIcon icon="fa-sign-in"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sign-out" /> <small>"FontAwesomeIcon icon="fa-sign-out"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-user" /> <small>"FontAwesomeIcon icon="fa-user"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-sliders" /> <small>"FontAwesomeIcon icon="fa-sliders"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-plus" />
              <small>"FontAwesomeIcon icon="fa-plus"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-floppy-disk" />
              <small>"FontAwesomeIcon icon="fa-floppy-disk"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-up" />
              <small>"FontAwesomeIcon icon="fa-chevron-up"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-down" />
              <small>"FontAwesomeIcon icon="fa-chevron-down"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-left" />
              <small>"FontAwesomeIcon icon="fa-chevron-left"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-chevron-right" />
              <small>"FontAwesomeIcon icon="fa-chevron-right"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-ellipsis" />
              <small>"FontAwesomeIcon icon="fa-ellipsis"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye" />
              <small>"FontAwesomeIcon icon="fa-eye"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-pen-to-square" />
              <small>"FontAwesomeIcon icon="fa-pen-to-square"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-trash-can" />
              <small>"FontAwesomeIcon icon="fa-trash-can"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-clone" />
              <small>"FontAwesomeIcon icon="fa-clone"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-circle-xmark" />
              <small> "FontAwesomeIcon icon="fa-circle-xmark"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-cloud-arrow-up" />
              <small> "FontAwesomeIcon icon="fa-cloud-arrow-up"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-calendar-days" />
              <small> "FontAwesomeIcon icon="fa-calendar-days"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye-slash" />
              <small> "FontAwesomeIcon icon="fa-eye-slash"</small>
            </h3>
            <h3>
              <FontAwesomeIcon icon="fa-eye" />
              <small> "FontAwesomeIcon icon="fa-eye-slash"</small>
            </h3>
          </div>
          <Button onClick={showModal} globalClass="mb-2" label="Open modal" stylesClass="btnSecondary" />
          <br />
          <Input name="testInput" label="Test label" errorTxt="Error message" placeholder="Enter..." globalClass="mb-2" />
          <Input name="testInput" label="Test label" placeholder="Enter..." globalClass="mb-1" />
          <br />
          <Input name="testInput" label="Test label" stylesClass="inputLabelNext" globalClass="my-3" />
          <br />
          <Button label="Test button" globalClass="my-2 w-100" />
          <br />
          <Button label="Test button" stylesClass="btnSecondary" iconRight={<i className="icon-right-arrow"></i>} />
          <br />
          <TextArea label="text label" rows={10} name="testtextarea" globalClass="mt-3" />
          <br />
          <InputPassword label="Password" value={password} onChange={e => setPassword(e.target.value)} name="password" />
          <br />
          <Checkbox name="testCheckbox" label="Test checkbox" checked={testCheck} onChange={e => setTestCheck(e.target.checked)} />
          <br />
          <div style={{ background: 'white', padding: '20px' }}>
            <RadioInput name="testRadio" label="Test checkbox" checked={testRadio === 'option1'} onChange={() => setTestRadio('option1')} />
            <br />
            <RadioInput name="testRadio" label="Test checkbox" checked={testRadio === 'option2'} onChange={() => setTestRadio('option2')} />
            <br />
            <SwitchBtn onChange={onSwitchChange} checked={testSwitch} />
            <br />
            <SwitchBtn onChange={onSwitchChange} checked={testSwitch} label="Switch label:" globalClass="pl-3" />
          </div>
          <br />
          <SelectInput
            options={options}
            handleChangeSelect={onSelectChange}
            selectedOption={options.find(e => e.id === selected.id) || null}
            customLabel="name"
            label="Select label:"
            globalClass="my-3"
          />
          <br />
          <SelectInput
            options={options}
            handleChangeSelect={onSelectChange}
            selectedOption={options.find(e => e.id === selected.id) || null}
            customLabel="name"
            label="Select label:"
            additionalClass="selectLabelNext"
            customComponents={{ Option: CustomOptionSelect, SingleValue: SingleValue }}
          />
          <DatePickerComp
            startDate={date ? new Date(date) : null}
            onChange={onDateChange}
            label="Date:"
            errorTxt={'Date is required!!'}
            globalClass="my-3"
            // showTime={true}
          />
          <DatePickerComp
            startDate={time ? new Date(time) : null}
            onChange={onDateTimeChange}
            label="Date:"
            errorTxt={'Date is required!!'}
            globalClass="my-3"
            showTimeSelectOnly={true}
            showTime={true}
            stylesClass={'timePicker'}
            format={calendarPreviewTimeFormat}
            placeholder={calendarPreviewTimeFormat}
          />
        </div>
        <div>
          <UploadMedia onImageChange={onImageChange} images={images} />
          <Table heading={headingTest2} data={dataTb} colSpan={3} name="users" globalClass="mb-5" />
        </div>
      </TwoColumns>
      <Table heading={headingTest} data={dataTb} colSpan={3} name="users2I" />
      <Table heading={headingTest2} data={dataTb} colSpan={3} name="users" globalClass="mb-5" tableWidth="initial" columnWidth="auto" />

      <Table
        heading={headingTest}
        data={dataTb}
        colSpan={3}
        tableWidth="initial"
        columnWidth="auto"
        name="users1"
        stylesClass="bordered"
        globalClass="mb-5"
      />
    </div>
  )
}

export default BasicComponents
