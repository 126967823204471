import { api } from '.'
import { setCredentials, setUser } from 'redux/reducers/authSlice'
import toastService from 'services/toastService'
import { label } from 'constants/labels'

export const authApiSlice = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: user => ({
        url: 'api/auth',
        method: 'POST',
        body: {
          mode: 'loginKlijent',
          socialType: 'base',
          data: {
            values: user
          }
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setCredentials(data))
        } catch (err) {
          console.log(err)
        }
      }
    }),
    // sendLogout: builder.mutation({
    //   query: () => ({
    //     url: '/auth/logout',
    //     method: 'POST'
    //   }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled
    //       console.log(data)
    //       dispatch(logOut())
    //       setTimeout(() => {
    //         dispatch(api.util.resetApiState())
    //       }, 1000)
    //     } catch (err) {
    //       console.log(err)
    //     }
    //   }
    // }),

    refresh: builder.mutation({
      query: () => ({
        url: '/auth/refresh',
        method: 'GET'
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data)
          const { accessToken } = data
          dispatch(setCredentials({ accessToken }))
        } catch (err) {
          console.log(err)
        }
      }
    }),
    getById: builder.query({
      query: id => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'get',
          socialType: 'base',
          data: {
            options: { where: { id } }
          }
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(setUser(data))
        } catch (err) {
          console.log(err)
        }
      }
    }),

    register: builder.mutation({
      query: data => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'register-client-web',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: data
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', 'Uspesno registrovan novi klijent')
        } catch (err) {
          console.log(err)
        }
      }
    }),

    forgotPassword: builder.mutation({
      query: email => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'forgot-password-web',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: email
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successForgotPasswordMail)
        } catch (err) {
          console.log(err)
        }
      }
    }),
    changeForgotPassword: builder.mutation({
      query: data => ({
        url: `/api/auth`,
        method: 'POST',
        body: {
          mode: 'change-forgot-password-web',
          socialType: 'base',
          name: 'auth',
          type: 'base',
          data: {
            values: data
          }
        }
      }),
      async onQueryStarted(arg, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          if (!data.err) toastService.show('success', label.successChangeForgotPasswordMail)
        } catch (err) {
          console.log(err)
        }
      }
    })
  })
})

export const {
  useLoginMutation,
  useRefreshMutation,
  useGetByIdQuery,
  useRegisterMutation,
  useForgotPasswordMutation,
  useChangeForgotPasswordMutation
} = authApiSlice
