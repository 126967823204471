import React, { useEffect, useState } from 'react'
import noImage from 'assets/images/noImg.jpg'

const Image = ({ src, onClick }) => {
  const [img, setImg] = useState(src)
  const [imgClass, setImgClass] = useState('imgVisible')
  const onError = () => {
    setImg(noImage)
    setImgClass('noImage')
  }

  useEffect(() => {
    setImg(src)
    return () => {
      setImgClass('imgVisible')
    }
  }, [src])

  return <img className={imgClass} src={img} onError={onError} onClick={onClick} />
}

export default Image
