import Input from 'components/input'
import React from 'react'
import styles from './inputMultiple.module.scss'
import SelectInput from 'components/selectInput'
import DatePickerComp from 'components/dataPicker'
import NoYearPickerComp from 'components/dataPicker/noYearPicker'
import PhoneInputComp from 'components/phoneInput'
import Checkbox from 'components/checkbox'

const Fields = ({ fields = [], data = {}, index, name, disabled, isPreview, onChange, errorRow, onKeyPress }) => {
  return (
    <div className={styles.inputs}>
      {fields.map((field, i) => {
        return (
          <div className={styles.item} key={`child_${i}_${field.dbName}`}>
            {field.type === 'input' && (
              <Input
                type={field.inputType}
                name={field.dbName}
                label={field.label}
                id={`${field.dbName}_${i}_${index}`}
                value={data[field.dbName]}
                onChange={e => onChange(e, index, field, name)}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
                onKeyPress={onKeyPress}
              />
            )}

            {field.type === 'select' && (
              <SelectInput
                keyName={`select_${name}_${field.dbName}_${i}`}
                options={field.options}
                handleChangeSelect={e => onChange(e, index, field, name)}
                selectedOption={field.options?.find(el => (field.selectValue ? el.naziv : el.id) === data[field.dbName] || null)}
                customLabel={field.selectLabel}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                filter={data?.filter && data.filter[field.collection]}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'multiselect' && (
              <SelectInput
                keyName={`select_${name}_${field.dbName}_${i}`}
                options={field.options}
                handleChangeSelect={e => onChange(e, index, field, name)}
                selectedOption={field.options?.filter(option => data[field.dbName]?.some(some => some === option?.id))}
                // selectedOption={data[field.dbName].map(selectedId => field.options.find(option => option.id === selectedId))}
                label={field.label}
                globalClass=""
                disabled={disabled}
                customLabel="naziv"
                customValue="id"
                isPreview={isPreview}
                // filter={data?.filter && data.filter[field.collection]}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
                multiselect
              />
            )}
            {field.type === 'datapicker' && (
              <DatePickerComp
                startDate={data[field.dbName] ? new Date(data[field.dbName]) : null}
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}

            {field.type === 'datapickerrange' && (
              <DatePickerComp
                startDate={data[field.start] ? new Date(data[field.start]) : null}
                endDate={data[field.end] ? new Date(data[field.end]) : null}
                selectsRange
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                globalClass="mb-2"
                // showTime={true}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'noyeardatapicker' && (
              <NoYearPickerComp
                startDate={data[field.dbName] ? new Date(data[field.dbName]) : null}
                onChange={date => onChange(date, index, field, name)}
                label={field.label}
                disabled={disabled}
                isPreview={isPreview}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'phone' && (
              <PhoneInputComp
                value={data[field.dbName] || field.defaultValue}
                label={field.label}
                placeholder={field.placeholder}
                onChange={phone => onChange(phone, index, field, name)}
                isPreview={disabled}
                disabled={disabled}
                errorTxt={errorRow && errorRow[name] && field.required && !data[field.dbName] && 'Obavezno polje'}
              />
            )}
            {field.type === 'checkbox' && (
              <Checkbox
                id={`${field.dbName}_${i}_${index}`}
                name={field.dbName}
                label={field.label}
                checked={data[field.dbName]}
                onChange={e => onChange(e, index, field, name)}
                disabled={disabled}
                globalClass="mt-3"
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Fields
