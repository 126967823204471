/** Queries for 'Tipove lica' */

import { tag } from 'api/rtkTags'
// eslint-disable-next-line
// import { initialEntityType } from 'constants/initialValues'
// import { label } from 'constants/labels'

export const queryGetPagedEntityTypes = (pageNumber, pageSize, options = {}) => {
  return {
    query: {
      name: 'data',
      mode: 'page',
      type: 'tipLica',
      data: {
        pageNumber,
        pageSize,
        options
      }
    },
    providesTags: {
      type: tag.entityTypes
    }
  }
}

export const queryGetAllEntityTypes = () => {
  return {
    query: {
      name: 'data',
      mode: 'all',
      type: 'tipLica',
      data: {
        options: {
          order: [['naziv', 'ASC']]
        }
      }
    },
    providesTags: {
      type: tag.entityTypes
    }
  }
}
// /**
//  * @param {initialEntityType} data
//  */
// export const queryAddEntityType = data => {
//   return {
//     query: {
//       name: 'data',
//       mode: 'new',
//       type: 'tipLica',
//       data: { values: data }
//     },
//     invalidatesTags: [tag.entityTypes],
//     successMsg: label.successMsgCreate + ' ' + label.msgEntityType
//   }
// }

// /**
// * @param {initialEntityType} data
// @param {string} id
// */
// export const queryUpdateEntityType = (data, id) => {
//   return {
//     query: {
//       name: 'data',
//       mode: 'update',
//       type: 'tipLica',
//       data: {
//         values: data,
//         options: {
//           where: {
//             id
//           }
//         }
//       }
//     },
//     invalidatesTags: [tag.entityTypes],
//     successMsg: label.successMsgEdit + ' ' + label.msgEntityType
//   }
// }
// /**
// @param {string} id
// */
// export const queryDeleteEntityType = id => {
//   return {
//     query: {
//       name: 'data',
//       mode: 'delete',
//       type: 'tipLica',
//       data: {
//         options: {
//           where: { id }
//         }
//       }
//     },
//     invalidatesTags: [tag.entityTypes],
//     successMsg: label.successMsgDelete + ' ' + label.msgEntityType
//   }
// }
