import React, { useEffect, useState } from 'react'
import styles from './inputMultiple.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Fields from './fields'
import { useLazyGetAllQuery } from 'api/dataApiSlice'
import DisappearMsg from 'components/disappearMsg'
import { label as translate } from 'constants/labels'
import ErrorMessage from 'components/errorMessage'
import { useDispatch } from 'react-redux'
import { getMultipleRequiredFields } from 'redux/reducers/staticSlice'

const MultipleInputs = ({
  name,
  fields = [],
  data = [],
  onAdd,
  onChange,
  onRemove,
  label = '',
  labelForm = '',
  stylesClass = 'defaultMulti',
  globalClass,
  showDesapiringMsg = false,
  disabled = false,
  hideDataWithId = false,
  isPreview = false,
  errorGroup,
  errorRow,
  onKeyPress
}) => {
  const dispatch = useDispatch()
  const [getData] = useLazyGetAllQuery()
  const [show, setShow] = useState(false)
  const [multiFields, setMultiFields] = useState(fields)

  const getRequiredFields = (name, fields) => {
    const temp = {}
    fields.forEach(e => {
      if (e.required) temp[name] = { ...temp[name], [e.dbName]: true }
    })
    dispatch(getMultipleRequiredFields(temp))
  }

  useEffect(() => {
    const getOptions = async () => {
      const asyncRes = await Promise.all(
        fields.map(async el => {
          if (el.rtkQuery) {
            const res = await getData(el.rtkQuery)
            el.options = res.data
          }

          return el
        })
      )
      setMultiFields(asyncRes)
    }
    getOptions()
    getRequiredFields(name, fields)
  }, [fields])

  return (
    <div className={`${styles[stylesClass]} ${globalClass} ${isPreview ? styles.preview : ''}  g-multi-input`}>
      <div className={styles.fields}>
        {data.length > 0 && labelForm && <h3>{labelForm}</h3>}
        {showDesapiringMsg && show && <DisappearMsg text={translate.msgSave} reset={() => setShow(false)} />}
        {data.map((item, i) => (
          <div
            key={`parent_${i}`}
            className={`${styles.field} ${i === 0 ? styles.firstField : ''}`}
            style={{ display: hideDataWithId && item.id ? 'none' : 'flex' }}
          >
            <Fields
              fields={multiFields}
              data={item}
              onChange={onChange}
              index={i}
              name={name}
              disabled={disabled}
              isPreview={isPreview}
              errorRow={errorRow}
              onKeyPress={onKeyPress}
            />
            {onRemove && !disabled && (
              <div className={styles.trash}>
                <FontAwesomeIcon
                  icon="fa-trash-can"
                  onClick={() => {
                    setShow(true)
                    onRemove(i, item, name)
                  }}
                />
              </div>
            )}
          </div>
        ))}
        {!disabled && (
          <div className={styles.addIcon} onClick={() => onAdd(name, fields)}>
            <FontAwesomeIcon icon="fa-plus" />
            {label && <span>{label}</span>}
          </div>
        )}
        {errorGroup && !data.length && <ErrorMessage message={errorGroup} globalClass="ml-5" />}
      </div>
    </div>
  )
}

export default MultipleInputs
