import React from 'react'
import styles from './navbar.module.scss'
import useWindowDimensions from 'hooks/onWindowResize'
import MobileNavbar from './mob'
import WebNavbar from './web'

const Navbar = () => {
  const { isMobile } = useWindowDimensions()
  // console.log('STORE', store)
  return <nav className={styles.wrapper}>{isMobile ? <MobileNavbar /> : <WebNavbar />}</nav>
}

export default Navbar
