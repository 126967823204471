export const conditionalMapClient = {
  drzava: {
    stateField: 'drzavaId',
    filters: {
      region: { filterKey: 'drzavaId', filterValue: null },
      township: { filterKey: 'drzavaId', filterValue: null },
      city: { filterKey: 'drzavaId', filterValue: null }
    },
    emptyFields: ['opstinaId', 'gradId', 'regionId']
  },
  region: {
    stateField: 'regionId',
    filters: {
      township: { filterKey: 'regionId', filterValue: null },
      city: { filterKey: 'regionId', filterValue: null },
      region: { filterKey: 'id', filterValue: null }
    },
    additionalStateFields: ['drzavaId'],
    emptyFields: ['opstinaId', 'gradId']
  },
  opstina: {
    stateField: 'opstinaId',
    filters: {
      city: { filterKey: 'opstinaId', filterValue: null },
      region: { filterKey: 'id', filterValue: null },
      township: { filterKey: 'id', filterValue: null }
    },
    additionalStateFields: ['drzavaId', 'regionId'],
    emptyFields: ['gradId']
  },
  grad: {
    stateField: 'gradId',
    filters: {
      // city: { filterKey: 'id', filterValue: null },
      region: { filterKey: 'id', filterValue: null },
      township: { filterKey: 'id', filterValue: null }
    },
    additionalStateFields: ['drzavaId', 'opstinaId', 'regionId']
  }
}

export const conditionalMapTownship = {
  drzava: {
    stateField: 'drzavaId',
    filters: {
      region: { filterKey: 'drzavaId', filterValue: null }
    },
    emptyFields: ['regionId']
  },
  region: {
    stateField: 'regionId',
    filters: {
      drzava: { filterKey: 'id', filterValue: null }
      // region: { filterKey: 'id', filterValue: null }
    },
    additionalStateFields: ['drzavaId']
  }
}

export const conditionalMapSeaCountryCity = {
  drzava: {
    stateField: 'drzavaId',
    filters: {
      city: { filterKey: 'drzavaId', filterValue: null }
    },
    emptyFields: ['gradId']
  },
  grad: {
    stateField: 'gradId',
    additionalStateFields: ['drzavaId']
  }
}

export const conditionalMapAnnouncement = {
  drzava: {
    stateField: 'drzavaId',
    filters: {
      region: { filterKey: 'drzavaId', filterValue: null },
      city: { filterKey: 'drzavaId', filterValue: null }
    },
    emptyFields: ['gradId', 'regionId']
  },
  region: {
    stateField: 'regionId',
    filters: {
      city: { filterKey: 'regionId', filterValue: null },
      region: { filterKey: 'id', filterValue: null }
    },
    additionalStateFields: ['drzavaId'],
    emptyFields: ['gradId']
  },
  grad: {
    stateField: 'gradId',
    additionalStateFields: ['drzavaId', 'regionId'],
    filters: {
      city: { filterKey: 'id', filterValue: null },
      region: { filterKey: 'id', filterValue: null }
    }
  },
  proizvod: {
    stateField: 'proizvodId',
    filters: {
      productsDesc: { filterKey: 'proizvodId', filterValue: null }
    },
    emptyFields: ['opisProizvodaId']
  },
  opisProizvoda: {
    stateField: 'opisProizvodaId',
    additionalStateFields: ['proizvodId']
  }
}

export const conditionalMapGoods = {
  granaPrivrede: {
    stateField: 'granaPrivredeId',
    filters: {
      goodsSubGroup: { filterKey: 'granaPrivredeId', filterValue: null }
    },
    emptyFields: ['kategorijaId']
  },
  kategorija: {
    stateField: 'kategorijaId',
    additionalStateFields: ['granaPrivredeId']
  }
}

export const conditionalMapCity = {
  drzava: {
    stateField: 'drzavaId',
    filters: {
      region: { filterKey: 'drzavaId', filterValue: null },
      township: { filterKey: 'drzavaId', filterValue: null }
    },
    emptyFields: ['opstinaId', 'regionId']
  },
  region: {
    stateField: 'regionId',
    filters: {
      township: { filterKey: 'regionId', filterValue: null }
    },
    additionalStateFields: ['drzavaId'],
    emptyFields: ['opstinaId']
  },
  opstina: {
    stateField: 'opstinaId',
    additionalStateFields: ['drzavaId', 'regionId']
  }
}

export const conditionalBusinesActivity = {
  delatnost: {
    stateField: 'delatnostId',
    filters: {
      podDelatnost: { filterKey: 'delatnostId', filterValue: null }
    },
    emptyFields: ['podDelatnostId']
  },
  podDelatnost: {
    stateField: 'podDelatnostId',
    additionalStateFields: ['delatnostId']
  }
}
