import React, { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'components/button'
import styles from './search.module.scss'
import { label } from 'constants/labels'
import { useLazyGetAllQuery } from 'api/dataApiSlice'
import { getDayRange, getMonthRange, getRangeEnd, getRangeStart, getYearRange, isObjectEmpty } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import Fields from './fields'
import { isObject } from 'lodash'

const Search = ({ fields, onSearch, onClose, defaultFields = [], globalClass = '' }) => {
  const ref = useRef(null)
  const [searchFields, setSearchFields] = useState(defaultFields || [])
  const [getData] = useLazyGetAllQuery()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getOptions = async () => {
      const temp = cloneDeep(fields)
      const asyncRes = await Promise.all(
        temp.map(async el => {
          if (el.rtkQuery) {
            const res = await getData(el.rtkQuery)
            el.options = res.data
          }
          return el
        })
      )
      setLoading(false)
      if (defaultFields.length) {
        defaultFields.forEach(e => {
          asyncRes.find(el => {
            if (el.dbField === e.dbField && el.type === e.type) {
              el.value = e.value
              if (e.dateValue) el.dateValue = e.dateValue
            }
          })
        })
      }
      setSearchFields(asyncRes)
    }
    getOptions()
  }, [])

  useEffect(() => {
    if (defaultFields.length) submitSearch()
  }, [])

  const onChange = async (fieldKey, fieldValue, rangeType, element) => {
    const clone = cloneDeep(searchFields)
    const updatedState = await Promise.all(
      clone.map(async item => {
        if (item.dbField === fieldKey) {
          if (item.type !== 'range' && !rangeType) {
            let tempDate
            if (item.type === 'date') {
              if (item.subType === 'date') tempDate = getDayRange(fieldValue)
              if (item.subType === 'month') tempDate = getMonthRange(fieldValue)
              if (item.subType === 'year') tempDate = getYearRange(fieldValue)
              item.dateValue = fieldValue
            }
            if (item.type === 'multiselect') fieldValue = fieldValue.map(x => x?.[item.keyValue])
            const newValue = item.type === 'date' ? tempDate : fieldValue
            if (item.dependentField && item.type === 'select') {
              const { dependentField, rtkQuery, dbField, updateOptionsDbField, havePostScript } = item.dependentField
              let res
              if (havePostScript) res = await getData(rtkQuery(null, { where: { [dependentField]: element[dbField] } }))
              else res = await getData(rtkQuery({ where: { [dependentField]: element[dbField] } }))

              if (res.data) {
                const index = clone.findIndex(e => e.dbField === updateOptionsDbField)
                clone[index].options = res.data
              }
            }
            return { ...item, value: newValue }
          } else if (rangeType && item.type === 'range') {
            if (item.dateValue) item.dateValue[rangeType] = fieldValue
            else item.dateValue = { [rangeType]: fieldValue }
            let tempDate
            if (item.subType === 'datapicker') {
              tempDate = rangeType === 'start' ? getRangeStart(fieldValue) : getRangeEnd(fieldValue)
            } else tempDate = fieldValue

            const newValue = { ...item, value: { ...item.value, [rangeType]: tempDate } }
            if (!fieldValue && !newValue.dateValue[rangeType] && !newValue.value[rangeType]) {
              delete newValue.value[rangeType]
              delete newValue.dateValue[rangeType]
            }
            return newValue
          }
        }

        return item
      })
    )
    setSearchFields(updatedState)
  }

  const submitSearch = () => {
    let searchObject = searchFields?.filter(obj => {
      if (isObject(obj.value)) {
        if (!isObjectEmpty(obj.value)) return obj
      } else {
        if (obj.value) return obj
      }
    })

    searchObject = {
      search: searchObject
    }
    onSearch(searchObject)
  }

  const onSearchReset = () => {
    onSearch({})
    const temp = cloneDeep(searchFields)
    const removeValues = temp.map(e => {
      if (Array.isArray(e.value)) e.value = []
      else if (e.value && isObject(e.value)) e.value = {}
      else e.value = null
      if (e.dateValue && e.dateValue instanceof Date) e.dateValue = null
      if (e.dateValue && isObject(e.dateValue)) e.dateValue = {}

      return e
    })
    setSearchFields(removeValues)
  }

  if (loading) return 'Loading...'
  return (
    <div className={`${styles.wrapper} ${globalClass}`} ref={ref}>
      <div className={`${styles.fields}`}>
        {/* <div className={`${styles.fields}`} style={{ width: `${afterWidt}px` }}> */}
        <Fields data={searchFields} onChange={onChange} />
        <div className={styles.button}>
          <Button
            onClick={submitSearch}
            label={label.search}
            globalClass="mt-2 mr-1 mb-1"
            iconLeft={<FontAwesomeIcon icon="fa-magnifying-glass" />}
          />
          <Button
            onClick={onSearchReset}
            stylesClass="btnSecondary"
            globalClass="mt-2 ml-2 mb-1"
            iconLeft={<FontAwesomeIcon icon="fa-trash-can" />}
          />
          {onClose && (
            <Button iconRight={<FontAwesomeIcon icon="fa-close" />} onClick={onClose} globalClass="mt-2 ml-2 mb-1" stylesClass="btnGray" />
          )}
        </div>
      </div>
    </div>
  )
}

export default Search
