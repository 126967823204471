import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { url } from 'environment'
import { localStorageService } from 'services/localStorage.service'
import { logOut, setCredentials } from 'redux/reducers/authSlice'
import { tagArray } from './rtkTags'
import { isTouchDevice } from 'utils'

const baseQuery = fetchBaseQuery({
  baseUrl: url,
  reducerPath: 'api',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.token ?? localStorageService.get('csct')
    const refreshToken = getState().auth?.refreshToken ?? localStorageService.get('cscrt')
    if (token) headers.set('x-access-token', `${token}`)
    if (refreshToken) headers.set('x-refresh-access-token', `${refreshToken}`)
    headers.set('is-mobile', isTouchDevice())
    headers.set('app', 'klijent')
    headers.set('device-id', localStorage.getItem('did'))
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // console.log(args) // request url, method, body
  // console.log('api',api) // signal, dispatch, getState()
  // console.log(extraOptions) //custom like {shout: true}

  let result = await baseQuery(args, api, extraOptions)

  // If you want, handle other status codes, too
  if (result?.error?.status === 403) {
    console.log('sending refresh token')

    // send refresh token to get new access token
    const refreshResult = await baseQuery('/api/auth/refresh', api, extraOptions)
    console.log('refreshResult', refreshResult)
    if (refreshResult?.data) {
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data }))

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions)
    } else {
      if (refreshResult?.error?.status === 403) {
        refreshResult.error.data.message = 'Your login has expired.'
      }
      return refreshResult
    }
  }
  if (result?.error?.status === 405) {
    console.log('ERORR forbiden')
    api.dispatch(logOut())
  }
  console.log('FETCH results***************', result)

  // return result
  return result.error ? result : result.data
}

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: tagArray,
  refetchOnMountOrArgChange: 60, // refetching after 60 sec
  // refetchOnFocus: true, // Refetch on window focus or tab switch.
  // refetchOnMountOrArgChange: true ,// Refetch on component mount.
  // refetchOnMountOrArgChange accepts either a boolean value, or a number as time in seconds.
  // refetchOnReconnect: true,// Re-fetching on network reconnection
  // refetch every api
  // if you want to refetch just some api-es then add as options to  hook const { data: Categories } = useGetDataQuery(arguments,{refetchOnFocus: true})
  // you can't add it to endpoint
  // eslint-disable-next-line no-unused-vars
  endpoints: builder => ({})
})
