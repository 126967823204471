import { queryGetAllBusinessActivity } from 'query/queryBusinessActivity'
import { queryGetAllSubBusinessActivity } from 'query/querySubBusinessActivity'
import { conditionalBusinesActivity } from './conditionalFields'
import { DATA_TYPES } from './other'

export const initialBusinessActivity = {
  naziv: '',
  aktivan: true
}

export const initialSubBusinessActivity = {
  naziv: '',
  delatnostId: null,
  aktivan: true
}

export const initalRegister = {
  pib: '',
  bpg: '',
  jmbg: '',
  delatnost: '',
  poddelatnost: '',
  lozinka: '',
  lozinkaPotvrda: '',
  poreskiObveznik: '',
  naziv: '',
  maticniBroj: '',
  pdv: false,
  tipLicaId: '',
  username: '',
  gradId: null,
  opstinaId: null,
  regionId: null,
  drzavaId: null,
  oblastId: null,
  status: 1,
  kontaktTelefon: '',
  email: '',
  sajt: '',
  direktor: '',
  direktorTelefon: '',
  connectingTables: {
    clientActivity: [],
    clientContacts: [],
    clientTimeOfContacts: [],
    goodsBuy: [],
    goodsSell: []
    // remark: ''
  }
}

export const initialLogin = {
  email: '',
  password: '',
  type: 'klijent'
}

export const multiFieldsContactsInitial = [
  { dbName: 'ime', label: 'Ime i prezime', type: 'input', inputType: 'text', required: true },
  { dbName: 'email', label: 'Email', type: 'input', inputType: 'text', required: true },
  { dbName: 'mobilni', label: 'Mobilni', type: 'phone', defaultValue: '', required: true },
  { dbName: 'funkcija', label: 'Funkcija', type: 'input', inputType: 'text' },
  { dbName: 'kontaktOglas', label: 'Kontakt u oglasima', type: 'checkbox' }
]

export const multiFieldsDelatnostInitial = [
  {
    dbName: 'delatnostId',
    collection: 'delatnost',
    label: 'Delatnost',
    type: 'select',
    selectLabel: 'naziv',
    rtkQuery: queryGetAllBusinessActivity(),
    conditional: conditionalBusinesActivity,
    required: true
  },
  {
    dbName: 'podDelatnostId',
    collection: 'podDelatnost',
    label: 'Poddelatnost',
    type: 'select',
    selectLabel: 'naziv',
    rtkQuery: queryGetAllSubBusinessActivity(),
    conditional: conditionalBusinesActivity,
    required: true
  }
]

export const initialCurrency = {
  naziv: '',
  kod: '',
  aktivan: true
}

export const initialWorkField = {
  naziv: '',
  aktivan: true
}

export const initialProductDesc = {
  naziv: '',
  proizvodId: null,
  aktivan: true
}

export const initialProduct = {
  naziv: '',
  aktivan: true,
  kategorijaId: null,
  jedinicaMereId: null,
  granaPrivrede: [],
  sifraGranaPrivrede: '',
  tipPodatka: DATA_TYPES.productsSerbia,
  rod: false
}

export const initialAnnouncement = {
  tip: 1,
  napomena: '',
  cena: null,
  kolicina: null,
  rod: null,
  status: null,
  proizvodId: null,
  opisProizvodaId: null,
  unetaJedinicaMereId: null,
  unetaCenaJedinicaMereId: null,
  podrazumevanaJedinicaMereId: null,
  podrazumevanaCenaJedinicaMereId: null,
  podrazumevanValutaId: null,
  klijentId: null,
  valutaId: '',
  kontakti: [],
  datumVazenja: null,
  kretanjeCena: false,
  robniIzvestaj: false,
  cenaVidljiva: false,
  oglasVidljiv: false,
  pdv: true,
  fcaDogovor: false,
  fcaKupac: false,
  connectingTables: {
    fca: []
  },
  tipPlacanja: 1
}
export const initialRealizations = {
  objavaKupcaId: null,
  objavaProdavcaId: null,
  kupacId: null,
  prodavacId: null,
  jedinicaMereId: null,
  valutaId: null,
  proizvodId: null,
  opisProizvodaId: null,
  regionId: null,
  gradId: null,
  datum: null,
  kolicina: null,
  cena: null,
  fcaKupac: false,
  fcaDogovor: false,
  napomenaProizvoda: '',
  komentar: '',
  tipPlacanja: null
}

export const initialConnectTbActivity = { delatnostId: null, podDelatnostId: null }
export const initialConnectTbContacts = { ime: '', email: '', telefon: '', funkcija: '' }
