import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import logoImg from 'assets/logo/logoWhite.png'
import logo from 'assets/logo/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './mob.module.scss'
import Button from 'components/button'
import { useDispatch, useSelector } from 'react-redux'
import { logOut } from 'redux/reducers/authSlice'
import useClickOutside from 'hooks/outsideClick'

const ToggleNav = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state.auth.token)
  const logoutUser = async () => {
    await dispatch(logOut())
    navigate('/', { replace: true })
  }

  return (
    <div className={styles.leftNav}>
      <div className={styles.info}>
        <img src={logo} />
        {token && (
          <>
            <h5>laslo_horvat</h5>
            <h6>laslo_horvat@email.com</h6>
          </>
        )}
      </div>

      <div className={styles.links}>
        <ul className={styles.listGroup}>
          <li>
            <NavLink to="basic">HyperEther</NavLink>
          </li>
          <li>
            <NavLink to="basic">Aktivni oglasi</NavLink>
          </li>
          <li>
            <NavLink to="basic">Prethodni oglasi</NavLink>
          </li>

          <li>
            <NavLink to="basic">Roba koju pratim</NavLink>
          </li>

          <li>
            <NavLink to="basic">Obavestenja</NavLink>
          </li>
          <li>
            <NavLink to="basic">Izvestaj cena</NavLink>
          </li>
          <li>
            <NavLink to="basic">Uvoz/Izvoz</NavLink>
          </li>
        </ul>
        <ul className={styles.listGroup}>
          <li>
            <NavLink to="basic">Aktuelnosti</NavLink>
          </li>
          <li>
            <NavLink to="basic">Subvencije i regulative</NavLink>
          </li>
        </ul>

        <ul className={styles.listGroup}>
          <li>
            <NavLink to="basic">Podesavanja</NavLink>
          </li>
          <li>
            <NavLink to="basic">Najcesca pitanja</NavLink>
          </li>
          <li>
            <NavLink to="basic">Prijavite problem</NavLink>
          </li>
          <li>
            <NavLink to="basic">Preporucite prijateljima</NavLink>
          </li>
        </ul>
        <ul className={styles.listGroup}>
          <li>
            <NavLink to="basic">Infotim</NavLink>
          </li>
          <li>
            <NavLink to="basic">Kontaktirajte nas</NavLink>
          </li>
          <li>
            <NavLink to="basic">Uslovi korišćenja</NavLink>
          </li>
        </ul>
        <div className={styles.btnGroups}>
          <Button onClick={logoutUser} label="Odjavi se" iconRight={<FontAwesomeIcon icon="fa-sign-out" />} globalClass="mt-2 w-100" />
        </div>
      </div>
    </div>
  )
}

const MobileNavbar = () => {
  const navigate = useNavigate()
  const [toggle, setToggle] = useState(false)
  const [userNavShow, setUserNavShow] = useState(false)
  const token = useSelector(state => state.auth.token)
  const { ref, isComponentVisible, setIsComponentVisible } = useClickOutside(toggle)

  useEffect(() => {
    if (!isComponentVisible) {
      setToggle(false)
      // setUserNavShow(false)
    }
  }, [ref, isComponentVisible])

  useEffect(() => {
    setIsComponentVisible(toggle)
  }, [toggle])

  // useEffect(() => {
  //   setIsComponentVisible(userNavShow)
  // }, [userNavShow])

  return (
    <div className={styles.wrapper}>
      {toggle && (
        <div ref={ref}>
          <ToggleNav />
        </div>
      )}
      <div className={styles.topNav}>
        <div className={styles.left}>
          {!toggle && <FontAwesomeIcon icon="fa-bars" onClick={() => setToggle(true)} />}
          <NavLink to="/" className={styles.logo}>
            <img src={logoImg} />
          </NavLink>
        </div>
        {token ? (
          <div className={styles.right}>
            <FontAwesomeIcon icon="fa-bell" />
            <FontAwesomeIcon icon="fa-file-lines" />
            <FontAwesomeIcon icon="fa-hand-holding-heart" />
            <FontAwesomeIcon icon="fa-circle-user" onClick={() => setUserNavShow(!userNavShow)} />
            {userNavShow && (
              <ul className={`${styles.dropdownUser}`}>
                <li>
                  <NavLink to="not-found">Aktivni oglasi</NavLink>
                </li>
                <li>
                  <NavLink to="not-found">Prethodni oglasi</NavLink>
                </li>
                <li>
                  <NavLink to="not-found">Dodaj oglas</NavLink>
                </li>
                <li>
                  <NavLink to="not-found">Roba koju pratim</NavLink>
                </li>
              </ul>
            )}
          </div>
        ) : (
          <div className={styles.btnGroup}>
            <Button
              stylesClass="btnLight"
              onClick={() => navigate('login')}
              label="Prijavi se"
              iconRight={<FontAwesomeIcon icon="fa-sign-out" />}
              globalClass="w-100"
            />
            <Button
              stylesClass="btnSecondary"
              onClick={() => navigate('register')}
              label="Registracija"
              iconRight={<FontAwesomeIcon icon="fa-sign-out" />}
              globalClass="w-100 ml-3"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default MobileNavbar
