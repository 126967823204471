import { BoxContainer } from 'components/containers/containers'
import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Button from '../../components/button/index'
import styles from './auth.module.scss'
// import toastService from 'services/toastService'
import { useRegisterMutation } from 'api/authApiSlice'
import SelectInput from 'components/selectInput'
import { conditionalMapClient } from 'constants/conditionalFields'
import { initalRegister, initialConnectTbActivity, initialConnectTbContacts, multiFieldsContactsInitial } from 'constants/initialValues'
import { CLIENT_CONNECTION_TB } from 'constants/other'
import cloneDeep from 'lodash/cloneDeep'
import { queryGetAllWorkField } from 'query/queryArea'
import { queryGetAllCity } from 'query/queryCity'
import { queryGetAllActivityByClientId, queryGetAllContactsByClientId } from 'query/queryClientConectionTables'
import { queryGetAllCountry } from 'query/queryCountry'
import { queryGetAllEntityTypes } from 'query/queryEntityType'
import { queryGetAllRegion } from 'query/queryRegion'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoadingData } from 'redux/reducers/staticSlice'
import { getCurrentTime } from 'utils'
import { useGetAllQuery } from '../../api/dataApiSlice'
import { validateClientsFarm as valdateFormFarm, validateClientsLegal as valdateFormLegal } from '../../components/forms/helpers/validate'
import FormClientsFarm from './formClientsFarm'
import FormClientsLegal from './formClientsLegal'

const Register = ({ existingData = {}, id }) => {
  const [error, setError] = useState(true)
  const [data, setData] = useState({ ...initalRegister })

  const { data: entityTypes = [] } = useGetAllQuery(queryGetAllEntityTypes())
  const multiDataInitial = {
    [CLIENT_CONNECTION_TB.activity]: { ...initialConnectTbActivity },
    [CLIENT_CONNECTION_TB.contact]: { ...initialConnectTbContacts }
  }
  const [multiDataList, setMultiDataList] = useState({
    [CLIENT_CONNECTION_TB.activity]: [],
    [CLIENT_CONNECTION_TB.contact]: []
  })
  const [multiError, setMultiError] = useState({})
  const requiredMultiMap = useSelector(state => state.static.multipleRequiredFields)
  const { data: countries = [] } = useGetAllQuery(queryGetAllCountry({ order: [['naziv', 'ASC']] }))
  const { data: cities = [] } = useGetAllQuery(queryGetAllCity({ order: [['naziv', 'ASC']] }))
  const { data: region = [] } = useGetAllQuery(queryGetAllRegion({ order: [['naziv', 'ASC']] }))
  const { data: workFieldData = [] } = useGetAllQuery(queryGetAllWorkField())
  const { data: clientActivityData = [] } = useGetAllQuery(queryGetAllActivityByClientId(existingData.id), { skip: !existingData.id })
  const { data: clientContactData = [] } = useGetAllQuery(queryGetAllContactsByClientId({ where: { klijentId: existingData.id } }), {
    skip: !existingData.id
  })
  const [newMultiConatctInitial, setNewMultiConatctInitial] = useState(multiFieldsContactsInitial)
  const [filterMap, setFilterMap] = useState({})
  const [isDomestic, setIsDomestic] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clientRegister] = useRegisterMutation()
  // const dispatch = useDispatch()

  useEffect(() => {
    if (!id && countries.length) {
      const foundDrzava = countries?.find(el => el.naziv?.toLocaleLowerCase().includes('srbija'))
      if (foundDrzava) {
        // automaticly add country and prefix to phone number when creating client
        getOptions('drzava', foundDrzava, {
          ...data,
          drzavaId: foundDrzava?.id || null,
          kontaktTelefon: foundDrzava?.pozivniBroj,
          direktorTelefon: foundDrzava?.pozivniBroj
        })
        // const tempMultiPhone = newMultiConatctInitial.map(e => {
        //   if (e.type === 'phone') e.defaultValue = foundDrzava?.pozivniBroj
        //   return e
        // })
        // setNewMultiConatctInitial(tempMultiPhone)
        setIsDomestic(true)
      }
    }
  }, [countries])

  useEffect(() => {
    if (data.naziv) {
      generateUsername()
    }
  }, [data.naziv])

  const handleChange = e => {
    let { name, value, type } = e.target
    if (type === 'number' && !value) value = null
    setData(prev => ({ ...prev, [name]: value }))
  }

  const generateUsername = () => {
    let modifiedString = data.naziv.replace(/ /g, '_')
    modifiedString = modifiedString.replace(/\./g, '')
    setData(prev => ({ ...prev, username: modifiedString }))
  }

  const handlePhoneChange = (type, number) => {
    setError(false)
    const phoneNum = number.startsWith('+') ? number : '+' + number
    setData(formData => ({ ...formData, [type]: phoneNum || null }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const clientActivityremoveFilter = multiDataList[CLIENT_CONNECTION_TB.activity].map(e => {
      if ('filter' in e) delete e.filter
      return e
    })

    const temp = {
      // ...rest,
      ...data,
      connectingTables: {
        clientActivity: clientActivityremoveFilter,
        clientContacts: multiDataList[CLIENT_CONNECTION_TB.contact]

        // remark
      }
    }

    const connectionTbExisting = {
      clientActivityData,
      clientContactData
    }

    const isValid =
      data.tipLicaSifra === 'GZ'
        ? valdateFormFarm(temp, connectionTbExisting, isDomestic)
        : valdateFormLegal(temp, connectionTbExisting, isDomestic)
    if (isValid) {
      onSubmit(temp, id)
    } else {
      // toastService.show('error', label.errRequiredAll)
      setError(true)
    }
  }

  const onSubmit = async data => {
    dispatch(setIsLoadingData(true))
    const res = await clientRegister(data)
    if (!res.error) navigate('/login')
    dispatch(setIsLoadingData(false))
  }

  // const handleSelectChange = async (e, name, conditional, isMultiselect, valueKey = 'id') => {
  //   setData(prev => ({ ...prev, [name]: e[valueKey] }))
  // }

  const getOptions = async (mapName, selectValue, data) => {
    const { newFilterMap, newData } = await pullConditionalFields(mapName, conditionalMapClient, selectValue, data)
    setFilterMap(newFilterMap)
    setData(newData)
    // setData(prev => ({ ...prev, ...newData }))
  }

  const handleSelectChange = async (e, name, conditional, isMultiselect, valueKey = 'id') => {
    const tempData = { ...data }
    if (name === 'drzavaId' && !id) {
      // automatically add prefix to phone number when creating client
      setIsDomestic(e.naziv.toLocaleLowerCase().includes('srbija'))
      tempData.kontaktTelefon = e?.pozivniBroj
      tempData.direktorTelefon = e?.pozivniBroj
    }
    if (conditional) {
      await getOptions(conditional, e, tempData)
    } else {
      setData(prev => ({ ...prev, [name]: e[valueKey] }))
    }
  }

  const handleCheckboxChange = e => {
    const { name, checked } = e.target
    setData(formData => ({ ...formData, [name]: checked ?? null }))
  }

  const onAddField = name => {
    const temp = cloneDeep(multiDataList[name])

    if (!checkIsMultiValid(name, temp)) return
    const foundDrzava = countries?.find(el => el.id === data.drzavaId)
    const tempMultiPhone = newMultiConatctInitial.map(e => {
      if (e.type === 'phone') e.defaultValue = foundDrzava?.pozivniBroj
      return e
    })
    setNewMultiConatctInitial(tempMultiPhone)
    temp.push(multiDataInitial[name])
    setMultiDataList(prev => ({ ...prev, [name]: temp }))
  }

  const onMultipleChange = async (e, index, field, name) => {
    const { type, dbName, collection, conditional } = field
    const multiList = cloneDeep(multiDataList)

    const temp = multiList[name]
    if (type === 'input') {
      const { name, value } = e.target
      temp[index][name] = value
    } else if (type === 'checkbox') {
      const { name, checked } = e.target
      temp[index][name] = checked
    } else if (type === 'select') {
      if (conditional) {
        const { newFilterMap, newData } = await pullConditionalFields(collection, conditional, e, temp[index])
        temp[index] = newData
        temp[index].filter = newFilterMap
      } else {
        temp[index][dbName] = e.id
      }
    } else if (type === 'datapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'noyeardatapicker') {
      temp[index][dbName] = e ? getCurrentTime(e) : null
    } else if (type === 'phone') {
      const phoneNum = e.startsWith('+') ? e : '+' + e
      temp[index][dbName] = phoneNum
    }

    setMultiDataList(multiList)
  }

  const pullConditionalFields = async (mapName, filterMap, selectValue, data) => {
    const { filters, additionalStateFields, emptyFields, stateField } = cloneDeep(filterMap[mapName])
    const newData = cloneDeep(data)
    if (filters) {
      Object.keys(filters).forEach(key => {
        filters[key].filterValue = selectValue.id
      })
    }
    newData[stateField] = selectValue.id

    if (additionalStateFields) {
      additionalStateFields.forEach(e => {
        newData[e] = selectValue[e]
      })
    }

    if (emptyFields) {
      emptyFields.forEach(e => {
        newData[e] = null
      })
    }
    return { newData, newFilterMap: filters }
  }

  const checkIsMultiValid = (name, data) => {
    const isValidArr = []
    if (requiredMultiMap[name]) {
      data.forEach(e => {
        Object.keys(requiredMultiMap[name]).forEach(key => {
          if (!e[key]) isValidArr.push(false)
        })
      })
    }
    if (!isValidArr.every(Boolean)) {
      setMultiError(prev => ({ ...prev, [name]: true }))
      return
    } else {
      setMultiError(prev => ({ ...prev, [name]: false }))
    }
    return isValidArr.every(Boolean)
  }

  const onRemoveMultiselect = (index, item, name) => {
    const temp = cloneDeep(multiDataList[name])
    temp.splice(index, 1)
    setMultiDataList(prev => ({ ...prev, [name]: temp }))
  }

  const handleSelectType = async value => {
    setData(prev => ({ ...prev, tipLicaSifra: value.sifra, tipLicaId: value.id }))
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        <BoxContainer wrapperClass="overflowUnset" globalClass="p-3 test">
          <div>
            <h2>Registracija</h2>
            <SelectInput
              options={entityTypes}
              handleChangeSelect={e => handleSelectType(e)}
              selectedOption={entityTypes.find(el => el.id === data.tipLicaId)}
              customLabel="naziv"
              customValue="tipLicaSifra"
              label="Tip lica"
              stylesClass="selectLabelNext"
              globalClass="mb-3"
              errorTxt={error && !data.tipLicaId && 'Tip lica je obavezno polje'}
              // errorTxt={error && !data.tipLicaSifra && label.typeEntityErr}
            />
            <SelectInput
              options={countries || []}
              filter={filterMap?.country}
              handleChangeSelect={e => handleSelectChange(e, 'drzavaId', 'drzava')}
              selectedOption={countries.find(el => el.id === data.drzavaId) || null}
              customLabel="naziv"
              customValue="id"
              label="Drzava"
              stylesClass="selectLabelNext"
              // errorTxt={error && !data.drzavaId && label.countryErr}
            />

            <div>
              {data.tipLicaSifra && (
                <>
                  {data.tipLicaSifra === 'GZ' ? (
                    <FormClientsFarm
                      data={data}
                      handleSelectChange={handleSelectChange}
                      handleChange={handleChange}
                      handleCheckboxChange={handleCheckboxChange}
                      handlePhoneChange={handlePhoneChange}
                      onAddField={onAddField}
                      onMultipleChange={onMultipleChange}
                      onRemoveMultiselect={onRemoveMultiselect}
                      // onRemarkChange={onRemarkChange}
                      // onRemarkEdit={onRemarkEdit}
                      // remarkList={remarkData}
                      error={error}
                      multiErrorRow={multiError}
                      filterMap={filterMap}
                      countries={countries}
                      cities={cities}
                      region={region}
                      workFieldData={workFieldData}
                      isDomestic={isDomestic}
                      multiFieldsContactsInitial={newMultiConatctInitial}
                      multiDataList={multiDataList}
                    />
                  ) : (
                    <FormClientsLegal
                      data={data}
                      handleSelectChange={handleSelectChange}
                      handleChange={handleChange}
                      handleCheckboxChange={handleCheckboxChange}
                      handlePhoneChange={handlePhoneChange}
                      onAddField={onAddField}
                      onMultipleChange={onMultipleChange}
                      onRemoveMultiselect={onRemoveMultiselect}
                      // onRemarkChange={onRemarkChange}
                      // onRemarkEdit={onRemarkEdit}
                      // remarkList={remarkData}
                      error={error}
                      multiErrorRow={multiError}
                      filterMap={filterMap}
                      countries={countries}
                      cities={cities}
                      region={region}
                      workFieldData={workFieldData}
                      isDomestic={isDomestic}
                      multiFieldsContactsInitial={newMultiConatctInitial}
                      multiDataList={multiDataList}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.btn}>
            <Button onClick={e => handleSubmit(e)} label="Registruj se" globalClass="mt-2 w-100" />
          </div>
          <div className="ta-c my-3">
            Nazad na <NavLink to="/login">Prijavu</NavLink>
          </div>
        </BoxContainer>
      </div>
    </form>
  )
}
export default Register
