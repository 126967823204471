import React from 'react'
import { BoxContainer } from 'components/containers/containers'

const MessageNoData = ({ message }) => {
  return (
    <BoxContainer>
      <h3>{message}</h3>
    </BoxContainer>
  )
}

export default MessageNoData
