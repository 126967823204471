import accounting from 'accounting-js'
import { dateDBFormat, monthFormat, yearFormat } from 'constants/date'
import dayjs from 'dayjs'

export const deepClone = obj => {
  if (typeof obj !== 'object' || obj === null) return obj
  const newObject = Array.isArray(obj) ? [] : {}

  for (const key in obj) {
    const value = obj[key]

    newObject[key] = typeof value === 'object' ? deepClone(value) : value
  }

  return newObject
}

export const sortDescendingNum = (data, key) => {
  const arrayForSort = [...data]
  return arrayForSort.sort((a, b) => {
    return parseFloat(b[key]) - parseFloat(a[key])
  })
}

export const sortAscendingNum = (data = [], key) => {
  const arrayForSort = [...data]
  return arrayForSort.sort((a, b) => {
    return parseFloat(a[key]) - parseFloat(b.order)
  })
}

export const isObjectEmpty = obj => {
  if (!obj) return true
  return Object.keys(obj).length === 0
}

export const trimObjectData = obj => {
  Object.keys(obj).map(k => {
    obj[k] = typeof obj[k] === 'string' ? obj[k].trim() : obj[k]
  })
  return obj
}

export const getMediaFromFileOrString = image => {
  if (!image && image === '') return ''
  else if (image instanceof File) {
    return URL.createObjectURL(image)
  } else if (image instanceof Element) {
    return image.toDataURL()
  } else {
    return image.link
  }
}

export const formatNumber = (num, type) => {
  if (!num) return '-'
  if (type === 'money') return accounting.formatMoney(parseFloat(num), { symbol: '', precision: 2 })
  else if (type === 'twoDecimals') return accounting.toFixed(parseFloat(num), 2)
  else return Number(parseFloat(num))
}

export const getCurrentTime = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const today = new Date()
  const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
  const dateTime = formated + ' ' + time
  return new Date(dateTime)
}
export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

export const getDayRange = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const start = new Date(formated + ':00:00:00')
  const end = new Date(formated + ':23:59:59')
  return { start, end }
}

export const getMonthRange = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  const dayInMonth = dayjs(formated).daysInMonth()
  const month = dayjs(formated).format(monthFormat)
  const year = dayjs(formated).format(yearFormat)
  const start = new Date(`${year}-${month}-01:00:00:00`)
  const end = new Date(`${year}-${month}-${dayInMonth}:23:59:59`)
  return { start, end }
}

export const getYearRange = date => {
  if (!date) return null
  const year = dayjs(date).format(yearFormat)
  const start = new Date(`${year}-01-01:00:00:00`)
  const end = new Date(`${year}-12-31:23:59:59`)
  return { start, end }
}
export const getRangeStart = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  return new Date(formated + ':00:00:00')
}

export const getRangeEnd = date => {
  if (!date) return null
  const formated = dayjs(date).format(dateDBFormat)
  return new Date(formated + ':23:59:59')
}
