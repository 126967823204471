import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import './i18n'
import { store } from 'redux/store'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AppRoutes } from 'routes/routes'

console.log('store index.js', store)
const root = ReactDOM.createRoot(document.getElementById('root'))

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}
root.render(
  <React.StrictMode>
    <React.Suspense fallback={<>Loading</>}>
      <Provider store={store}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
