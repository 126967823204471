// search icons here https://fontawesome.com/search?m=free&o=r

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faGavel,
  faDashboard,
  faMoneyBillWheat,
  faCircleUser,
  faBars,
  faClose,
  faChevronDown,
  faSignIn,
  faSignOut,
  faUser,
  faSliders,
  faPlus,
  faFloppyDisk,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faEllipsis,
  faEye,
  faPenToSquare,
  faTrashCan,
  faClone,
  faSpinner,
  faCircleXmark,
  faMagnifyingGlass,
  faCloudArrowUp,
  faCalendarDays,
  faEyeSlash,
  faBell,
  faHandHoldingHeart,
  faFileLines,
  faListUl,
  faArrowTrendUp,
  faArrowRightArrowLeft,
  faLandmark,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faGavel,
  faDashboard,
  faMoneyBillWheat,
  faCircleUser,
  faBars,
  faClose,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faSignIn,
  faSignOut,
  faUser,
  faSliders,
  faPlus,
  faFloppyDisk,
  faEllipsis,
  faEye,
  faPenToSquare,
  faTrashCan,
  faClone,
  faSpinner,
  faCircleXmark,
  faCloudArrowUp,
  faMagnifyingGlass,
  faCalendarDays,
  faEyeSlash,
  faEye,
  faBell,
  faHandHoldingHeart,
  faFileLines,
  faListUl,
  faArrowTrendUp,
  faArrowRightArrowLeft,
  faLandmark,
  faEnvelope
)
