import ModalWrapper from 'components/modal'
import Navbar from 'components/navbar'
import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'components/icons'
import './App.scss'

const App = () => {
  const store = useSelector(state => state)
  return (
    <main>
      {store.modal.arr?.length
        ? store.modal.arr.map(e => (
            <React.Fragment key={e.order}>
              <ModalWrapper
                show={store.modal.active === e.order}
                content={e.content}
                size={e.size}
                customClass={e.customClass}
                iconClose={e.iconClose}
              />
            </React.Fragment>
          ))
        : null}
      {/* <Socket/> */}
      <ToastContainer className="toast" toastClassName="toast-wrapper" bodyClassName="toast-body" closeButton={false} />
      <div className="main-content">
        <Navbar />
        <Outlet />
      </div>
    </main>
  )
}

export default App
