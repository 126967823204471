import React from 'react'
import styles from './checkbox.module.scss'
import ErrorMessage from '../errorMessage'
import { PropTypes } from 'prop-types'

const Checkbox = ({ onChange, stylesClass = 'defaultCheckbox', globalClass = '', name, label, checked, disabled, id, errorTxt }) => {
  return (
    <div className={`${styles[stylesClass]} ${globalClass} g-checkbox`}>
      <div className={styles.checkBox}>
        <input
          name={name}
          id={id ?? name}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className={styles.checkmark}
        />
        <label className={styles.label} htmlFor={id ?? name}>
          {label}
        </label>
      </div>
      {errorTxt && <ErrorMessage message={errorTxt} />}
    </div>
  )
}

Checkbox.propTypes = {
  onChange: PropTypes.func,
  stylesClass: PropTypes.string,
  globalClass: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  errorTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
}

export default Checkbox
