import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForgotPasswordMutation } from 'api/authApiSlice'
import { BoxContainer } from 'components/containers/containers'
import { label } from 'constants/labels'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { setIsLoadingData } from 'redux/reducers/staticSlice'
import Button from '../../components/button/index'
import FormMailResetPassword from '../../components/forms/formMailResetPassword'
import styles from './auth.module.scss'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.static.isLoading)
  const [showFormMail, setShowFormMail] = useState(false)
  const [forgotPasswordSendMail] = useForgotPasswordMutation()
  const navigate = useNavigate()

  const handleResetType = () => () => {
    setShowFormMail(true)
  }

  const onSubmit = async data => {
    if (data) {
      try {
        dispatch(setIsLoadingData(true))
        await forgotPasswordSendMail(data)
      } catch (error) {
        dispatch(setIsLoadingData(false))
      }
    }
    dispatch(setIsLoadingData(false))
    navigate('/login')
  }

  return (
    <div className={styles.wrapper}>
      <BoxContainer globalClass="p-3">
        {!showFormMail ? (
          <div>
            <h2>Zaboravili ste lozinku?</h2>
            <Button
              onClick={handleResetType('mail')}
              label={label.resetMail}
              iconLeft={<FontAwesomeIcon icon="fa-envelope" />}
              globalClass="mt-2 w-100"
              disabled={isLoading}
              loading={isLoading}
            />
          </div>
        ) : (
          <BoxContainer globalClass="p-3">{<FormMailResetPassword onSubmit={onSubmit} />}</BoxContainer>
        )}

        <div className="ta-c mt-2">
          Nazad na <NavLink to="/login">Prijavu</NavLink>
        </div>
      </BoxContainer>
    </div>
  )
}

export default ForgotPassword
