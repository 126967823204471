import { useGetByIdQuery } from 'api/authApiSlice'
import { useUpdateCreateOneMutation } from 'api/dataApiSlice'
import { queryAddActivity } from 'query/queryActivity'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { selectCurrentId, selectCurrentUser } from 'redux/reducers/authSlice'
import { localStorageService } from 'services/localStorage.service'

const Auth = () => {
  const location = useLocation()
  const id = useSelector(selectCurrentId)
  const user = useSelector(selectCurrentUser)
  const [skip, setSkip] = useState(true)
  const [create] = useUpdateCreateOneMutation()

  useGetByIdQuery(id, { skip })

  useEffect(() => {
    if (id && !user) setSkip(false)
    return () => {
      setSkip(true)
    }
  }, [id, user])

  useEffect(() => {
    if (location && id) {
      const page =
        localStorage.getItem('page') && localStorage.getItem('page') !== 'undefined' ? JSON.parse(localStorage.getItem('page')) : null
      const setActivity = async () => {
        await create(queryAddActivity(id, 'visited', null, { path: location.pathname }))
      }
      if (!page) localStorageService.set('page', location.pathname)
      else if (page !== location.pathname) {
        localStorageService.set('page', location.pathname)
        setActivity()
      }
    }
  }, [location])

  return localStorageService.isAuth() ? (
    <div className="auth-wrapper">
      <section>
        <Outlet />
      </section>
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}
export default Auth
