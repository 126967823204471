import React from 'react'
import dayjs from 'dayjs'
import styles from './table.module.scss'
import MessageNoData from 'components/messageNoData'
import { FIELD_TYPES } from 'constants/other'
import { dateFormat } from 'constants/date'
import PreviewImages from 'components/previewImages'
import BtnActions from 'components/btnActions'
import CustomPagination from 'components/pagination'
import { label } from 'constants/labels'
import Button from 'components/button'
import { formatNumber } from 'utils'

export const getFieldValue = (data, head, onAction, map) => {
  let value = data[head.dbName]
  if (head.nestedObj) value = data[head.dbName]?.[head.nestedObj]
  let newValue
  switch (head.type) {
    case FIELD_TYPES.text:
      newValue = value ?? '-'
      break
    case FIELD_TYPES.decimal:
      newValue = formatNumber(value, head.numFormat)
      break
    case FIELD_TYPES.date:
      newValue = dayjs(value).format(head.format || dateFormat)
      break
    case FIELD_TYPES.range:
      newValue =
        (data[head.dbName1] ? dayjs(data[head.dbName1]).format(head.format || dateFormat) : 'N/A') +
        ' - ' +
        (data[head.dbName2] ? dayjs(data[head.dbName2]).format(head.format || dateFormat) : 'N/A')
      break
    case FIELD_TYPES.join:
      {
        const jm = data[head.dbName3]?.oznaka || ''
        const valuta = data[head.dbName2]?.simbol + (jm ? '/' : '')
        newValue = formatNumber(data[head.dbName1], head.numFormat) + '  ' + (head.dbName2 ? valuta : '') + jm
      }
      break

    case FIELD_TYPES.joinTwo:
      newValue = data[head.dbName1] ? formatNumber(data[head.dbName1], head.numFormat) + '  ' + data[head.dbName2] : '-'
      break
    case FIELD_TYPES.joinThree:
      newValue = Number(data[head.dbName1]) + '/' + Number(data[head.dbName2]) + '  ' + data[head.dbName3]
      break
    case FIELD_TYPES.joinFour:
      newValue =
        Number(data[head.dbName1]) + '/' + Number(data[head.dbName2]) + '  ' + data[head.dbName3]?.simbol + '/' + data[head.dbName4]?.oznaka
      break
    case FIELD_TYPES.boolean:
      newValue = value ? head.isTrueLabel || 'Da' : head.isFalseLabel || 'Ne'
      break
    case FIELD_TYPES.link:
      newValue = (
        <div className={styles.link}>
          <a href={value} target="_blank" rel="noreferrer">
            {head.linkText}
          </a>
        </div>
      )
      break
    case FIELD_TYPES.arrOfObject:
      {
        if (data?.[head.dbName].length)
          newValue = data[head.dbName].map((e, i) => <div key={`${head.dbObjProp}_${i}`}>{e?.[head.dbObjProp] ?? '-'}</div>)
        else newValue = '-'
      }
      break
    case FIELD_TYPES.disableEnable:
      newValue = (
        <div>
          <Button
            label={value ? 'Odblokiraj' : 'Blokiraj'}
            stylesClass={value ? 'btnDanger' : 'btnPrimary'}
            onClick={() => onAction(data, value ? 'enable' : 'disable')}
            globalClass="w-100"
          />
        </div>
      )
      break
    case FIELD_TYPES.maped: {
      const temp = map?.[head.map]
      newValue = temp && temp[value] ? temp[value][head.mapLabel] : null
      break
    }
    case FIELD_TYPES.image:
      newValue = <PreviewImages imgs={value} />
      break
    case FIELD_TYPES.actions:
      newValue = <BtnActions actions={head.actions} onClick={onAction} data={data} isPopup={head.isPopup} />
      break
    case FIELD_TYPES.customGoodsImportAndExport:
      newValue = value.split('/n').map((item, index) => (
        <div key={`${index}_${head.dbName}`} style={{ paddingLeft: item.startsWith('-') ? '20px' : '0' }}>
          {item.replace(/^(?!- )- /gm, '')}
        </div>
      ))
      break
    case FIELD_TYPES.status: {
      switch (value) {
        case 1:
          newValue = <div className={`${styles.statusBtn} ${styles.btnNew}`}>Novo</div>
          break
        case 2:
          newValue = (
            <div div className={`${styles.statusBtn} ${styles.btnView}`}>
              Pregledan
            </div>
          )
          break
        case 3:
          newValue = (
            <div div className={`${styles.statusBtn} ${styles.btnAdded}`}>
              Dodato u sistem
            </div>
          )
          break
        case 4:
          newValue = (
            <div div className={`${styles.statusBtn} ${styles.btnDeclined}`}>
              Odbijen
            </div>
          )
          break
        case 5:
          newValue = (
            <div div className={`${styles.statusBtn} ${styles.btnActive}`}>
              Aktivan
            </div>
          )
          break
        case 6:
          newValue = (
            <div div className={`${styles.statusBtn} ${styles.btnInActive}`}>
              Neaktivan
            </div>
          )
          break
        default:
          break
      }

      break
    }
    default:
      newValue = value
      break
  }
  return newValue
}

const isError = data => {
  if (data === '' || data === '-' || data === 'Invalid Date') return styles.error
  else return ''
}

const Table = ({
  stylesClass = 'list',
  globalClass = '',
  heading = [],
  data = [],
  colSpan = 0,
  columnWidth = 'initial',
  tableWidth = '100%',
  name = '',
  onAction,
  haveActionInline = false,
  onRowClick,
  havePagination = false,
  onPaginationChange,
  pageSize = 20,
  pageNumber = 1,
  total,
  map
}) => {
  const tbColumnStyle = {
    minWidth: columnWidth,
    width: columnWidth
  }
  const classPopup = e => (e.type === 'actions' ? (e.isPopup ? 'havePopup' : 'btnActions') : '')
  const getHeaders = () => {
    return (
      <>
        <tr>
          {heading.map(el => (
            <th
              key={`${name}_${el.dbName || el.dbName1}`}
              className={`th_${el.dbName} ${classPopup(el)}`}
              style={el.style || tbColumnStyle}
            >
              <div>
                <span>{el.label}</span>
              </div>
            </th>
          ))}
        </tr>
      </>
    )
  }
  const getBody = () => {
    return (
      <>
        {data.length ? (
          data.map((e, i) => (
            <tr key={`tb_${i}`} onClick={() => onRowClick && onRowClick(e)} className={`${!onRowClick ? styles.noCursor : ''}`}>
              {heading.map(head => {
                return (
                  <td
                    key={`${name}_${head.dbName || head.dbName1}`}
                    className={`
                    th_${head.dbName}
                    ${head.haveErr ? isError(getFieldValue(e, head, onAction)) : ''} 
                    `}
                    style={head.style || tbColumnStyle}
                  >
                    {getFieldValue(e, head, onAction, map)}
                  </td>
                )
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={colSpan || heading.length}>
              <MessageNoData message={label.messageNoData} />
            </td>
          </tr>
        )}
      </>
    )
  }

  return (
    <div className={`${styles[stylesClass]} ${globalClass} tb-custom`}>
      <div className={styles.tbWrapper}>
        <table style={{ width: tableWidth }} className={haveActionInline ? styles.inlineAction : ''}>
          <thead>{getHeaders()}</thead>
          <tbody>{getBody()}</tbody>
        </table>
      </div>
      {!!(havePagination && data.length) && (
        <CustomPagination pageSize={pageSize} page={pageNumber} onChange={onPaginationChange} total={total} />
      )}
    </div>
  )
}

export default Table
