/** Queries for 'Aktivnosti korisnika' */

// import { tag } from 'api/rtkTags'
// import { label } from 'constants/labels'

export const queryAddActivity = (id, action, entity, data) => {
  return {
    query: {
      name: 'data',
      mode: 'new',
      type: 'aktivnostKorisnika',
      data: {
        values: {
          tip: 'klijent',
          klijentId: id,
          akcija: action,
          entitet: entity,
          podaci: data
        }
      }
    }
    // invalidatesTags: [tag.businessActivity],
    // successMsg: label.successMsgCreate + ' ' + label.msgActivity
  }
}
