import { createSlice } from '@reduxjs/toolkit'
const dataSlice = createSlice({
  name: 'data',
  initialState: { data: null },
  reducers: {
    setData: (state, action) => {
      // const { accessToken } = action.payload
      state.user = action.payload
    }
  }
})

export const { setData } = dataSlice.actions

export default dataSlice.reducer
