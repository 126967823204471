export const localStorageService = {
  destroy() {
    localStorage.clear()
  },

  set(type, data) {
    localStorage.setItem(type, JSON.stringify(data))
  },

  remove(type) {
    localStorage.removeItem(type)
  },
  get(type) {
    const data = localStorage.getItem(type)
    if (!data) return null
    const res = JSON.parse(data)
    return res
  },

  isAuth() {
    const data = localStorage.getItem('csct')
    if (!data) {
      return false
    } else {
      const session = JSON.parse(data)
      return session
    }
  }
}
