import { label } from 'constants/labels'
import { validateEmail } from 'utils'

export const errClientRegister = (type, data) => {
  let message
  switch (type) {
    case 'pib':
      if (!data.pib) message = `PIB je obavezno polje`
      else if (data.pib && data.pib.length !== 9) message = `PIB mora imati 9 karaktera`
      break
    case 'email':
      if (!data.email) message = `Email je obavezno polje`
      else if (data.email && !validateEmail(data.email)) message = `Email nije validan`
      break
    case 'lozinka':
      if (!data.lozinka) message = `${label.passwordNewErr}`
      else if (data.lozinka.length < 8) message = `${label.passwordLengthErr}`
      else if (!/[A-Z]/.test(data.lozinka)) message = `${label.passwordCapitalErr}`
      else if (!/\d/.test(data.lozinka)) message = `${label.passwordNumberErr}`
      else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.lozinka)) message = `${label.passwordSpecialCharErr}`
      break
    case 'lozinkaPotvrda':
      if (!data.lozinkaPotvrda) message = `${label.passwordConfirmErr}`
      else if (data.lozinka && data.lozinkaPotvrda && data.lozinka !== data.lozinkaPotvrda) message = `${label.passwordConfirmEqualErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errAnnouncement = type => {
  let message
  switch (type) {
    case 'naziv':
      message = `${label.nameInputErr}`
      break
    case 'opis':
      message = `${label.descriptionErr}`
      break
    case 'cena':
      message = `${label.priceErr}`
      break
    case 'utovar':
      message = `${label.francoRegionErr}`
      break

    case 'kolicina':
      message = `${label.amountErr}`
      break
    case 'tipPlacanja':
      message = `${label.paymentTypeErr}`
      break

    case 'status':
      message = `${label.statusErr}`
      break
    case 'proizvodId':
      message = `${label.productErr}`
      break
    case 'opisProizvodaId':
      message = `${label.productDesErr}`

      break
    case 'unetaJedinicaMereId':
      message = `${label.measureUnitsInputErr}`
      break
    case 'cenaJedinicaMereId':
      message = `${label.measureUnitsInputErr}`
      break

    case 'datumVazenja':
      message = `${label.dateDurationInputErr}`
      break

    case 'ponuda/potraznja':
      message = `${label.bidDemandErr}`
      break
    case 'valutaId':
      message = `${label.currencyErr}`
      break
    case 'kontakti':
      message = `${label.contactErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errForgotPassword = (type, data) => {
  let message
  switch (type) {
    case 'email':
      if (!data.email) message = `${label.emailReq}`
      else if (data.email && !validateEmail(data.email)) message = `${label.emailErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}

export const errChangeForgotPassword = (type, data) => {
  let message
  switch (type) {
    case 'lozinka':
      if (!data.lozinka) message = `${label.passwordNewErr}`
      else if (data.lozinka.length < 8) message = `${label.passwordLengthErr}`
      else if (!/[A-Z]/.test(data.lozinka)) message = `${label.passwordCapitalErr}`
      else if (!/\d/.test(data.lozinka)) message = `${label.passwordNumberErr}`
      else if (!/[!@#$%^&*(),.?":{}|<>]/.test(data.lozinka)) message = `${label.passwordSpecialCharErr}`
      break
    case 'lozinkaPotvrda':
      if (!data.lozinkaPotvrda) message = `${label.passwordConfirmErr}`
      else if (data.lozinka && data.lozinkaPotvrda && data.lozinka !== data.lozinkaPotvrda) message = `${label.passwordConfirmEqualErr}`
      break
    default:
      message = 'Greska'
      break
  }
  return message
}
