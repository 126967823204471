import React from 'react'
import { ReactComponent as IconFb } from 'assets/icons/fb.svg'
import { ReactComponent as IconInstagram } from 'assets/icons/instagram.svg'
import { ReactComponent as IconLinkedin } from 'assets/icons/linkedin.svg'
import styles from './navbar.module.scss'

const SocialMedia = ({ stylesClass = 'socialWeb' }) => {
  return (
    <div className={styles[stylesClass]}>
      <a className="c-b" href="https://www.facebook.com/infotimlogistika/" target="_blank" rel="noreferrer">
        <IconFb />
      </a>
      <a className="c-b" href="https://www.linkedin.com/company/info-tim-logistika-doo/" target="_blank" rel="noreferrer">
        <IconLinkedin />
      </a>
      <a className="c-b" href="https://www.instagram.com/infotimlogistika/?hl=sr" target="_blank" rel="noreferrer">
        <IconInstagram />
      </a>
    </div>
  )
}

export default SocialMedia
