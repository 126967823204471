import { validateEmail } from 'utils'

export const validateClientsLegal = (data, existingConnectionTb, isDomestic) => {
  const { connectingTables } = data

  const hasValidLength = data.lozinka.length >= 8
  const hasCapitalLetter = /[A-Z]/.test(data.lozinka)
  const hasNumber = /\d/.test(data.lozinka)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(data.lozinka)

  const basic = [
    data.pib,
    data.naziv,
    data.drzavaId,
    data.gradId,
    data.regionId,
    data.kontaktTelefon,
    data.kontaktTelefon.length >= 10,
    data.direktor,
    data.email,
    validateEmail(data.email),
    data.username,
    data.lozinka,
    data.lozinkaPotvrda
  ].every(Boolean)

  let domesticFields = true
  if (isDomestic) {
    domesticFields = [data.regionId, data.pib.length === 9].every(Boolean)
  }

  const connectTb = data.id
    ? [
        existingConnectionTb.clientActivityData?.length || connectingTables.clientActivity?.length,
        existingConnectionTb.clientContactData?.length || connectingTables.clientContacts?.length
      ].every(Boolean)
    : [connectingTables.clientActivity?.length, connectingTables.clientContacts?.length].every(Boolean)

  const passwordCriteriaMet = hasValidLength && hasCapitalLetter && hasNumber && hasSpecialChar

  return [basic, connectTb, domesticFields, passwordCriteriaMet].every(Boolean)
}

export const validateClientsFarm = (data, existingConnectionTb, isDomestic) => {
  const { connectingTables } = data

  const hasValidLength = data.lozinka.length >= 8
  const hasCapitalLetter = /[A-Z]/.test(data.lozinka)
  const hasNumber = /\d/.test(data.lozinka)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(data.lozinka)

  const basic = [
    data.pib,
    data.bpg,
    data.drzavaId,
    data.gradId,
    data.regionId,
    data.kontaktTelefon,
    data.kontaktTelefon.length >= 10,
    data.direktor,
    data.email,
    validateEmail(data.email),
    data.username,
    data.lozinka,
    data.lozinkaPotvrda
  ].every(Boolean)

  let domesticFields = true
  if (isDomestic) domesticFields = [data.regionId].every(Boolean)

  const connectTb = data.id
    ? [
        existingConnectionTb.clientActivityData?.length || connectingTables.clientActivity?.length,
        existingConnectionTb.clientContactData?.length || connectingTables.clientContacts?.length
      ].every(Boolean)
    : [connectingTables.clientActivity?.length, connectingTables.clientContacts?.length].every(Boolean)

  const passwordCriteriaMet = hasValidLength && hasCapitalLetter && hasNumber && hasSpecialChar

  return [basic, connectTb, domesticFields, passwordCriteriaMet].every(Boolean)
}

export const validateAnnouncement = (data, existingFca) => {
  let basic = [
    data.tip,
    data.status,
    data.proizvodId,
    data.unetaJedinicaMereId,
    data.kolicina,
    data.datumVazenja,
    data.kontakti.length,
    data.tipPlacanja
  ]

  const fca =
    (existingFca?.length > 0 && data?.connectingTables?.fcaDelete.length < existingFca?.length) ||
    data?.connectingTables?.fcaCreate?.length > 0
  if (data.tip === 1) {
    // eslint-disable-next-line
    const additional = fca || data.fcaKupac
    basic = [...basic, data.cena, data.valutaId, data.unetaCenaJedinicaMereId, additional]
  } else if (data.tip === 2) {
    // eslint-disable-next-line
    const additional = fca || data.fcaKupac || data.fcaDogovor
    basic.push(additional)
  }

  return basic.every(Boolean)
}

export const validateForgotPasswordMail = data => {
  return data.email && validateEmail(data.email)
}

export const validateChangeForgotPasswordMail = data => {
  const hasValidLength = data.lozinka.length >= 8
  const hasCapitalLetter = /[A-Z]/.test(data.lozinka)
  const hasNumber = /\d/.test(data.lozinka)
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(data.lozinka)

  return (
    data.lozinka &&
    data.lozinkaPotvrda &&
    data.lozinka === data.lozinkaPotvrda &&
    hasValidLength &&
    hasCapitalLetter &&
    hasNumber &&
    hasSpecialChar
  )
}
